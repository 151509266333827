import { ActionList, Button, Link, Modal, Popover, TextField, Tooltip } from "@shopify/polaris"
import {
  ClipboardIcon,
  DuplicateIcon,
  GiftCardIcon, HeartIcon,
  IconsFilledIcon,
  ShareIcon, XIcon,
} from "@shopify/polaris-icons"
import copy from "copy-to-clipboard"
import { Observer } from "mobx-react"
import React, { useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { useIntercom } from "react-use-intercom"

import PolarisIndicator from "@/component/PolarisIndicator"
import { ShareUrl, SharingIcons } from "@/component/SharingBanner/SharingBanner"
import { SpecialOfferType } from "@/models/IUserInfo"
import stores from "@/stores"
import toast from "@/utils/toast"

import styles from "./index.module.scss"

enum PopupType {
  FreeQuota = "FreeQuota",
  RemoveBrand = "RemoveBrand",
  RecommendFriends = "RecommendFriends",
  PartnerFriendly = "PartnerFriendly",
  Dismiss = "Dismiss",
}

const SpecialOffer = () => {
  const { showNewMessages } = useIntercom()
  const { userInfoSore } = stores
  const [showPopover, setShowPopover] = useState(false)
  const { t } = useTranslation("dashboard")
  const { t: commonT } = useTranslation("common")

  const [popupStates, setPopupStates] = useState<Record<PopupType, boolean>>({
    [PopupType.FreeQuota]: false,
    [PopupType.RemoveBrand]: false,
    [PopupType.RecommendFriends]: false,
    [PopupType.PartnerFriendly]: false,
    [PopupType.Dismiss]: false,
  })

  const togglePopup = (type: PopupType) => {
    if (showPopover) setShowPopover(false)
    setPopupStates((prevStates) => ({
      ...prevStates,
      [type]: !prevStates[type], // 切换当前弹窗的状态
    }))
  }


  let text_message = ""
  const checkT = userInfoSore.data.is_show_partner_friendly_banner ?? 0

  if (checkT === 1) {
    text_message = commonT("PartnerFriendlyBanner.content_1")
  }
  if (checkT === 2) {
    text_message = commonT("PartnerFriendlyBanner.content_2")
  }

  // SpecialOffer按钮点击
  const handleSpecialOfferClick = (status: SpecialOfferType) => {
    setShowPopover(!showPopover)
    if (status === SpecialOfferType.ShowAll)
      userInfoSore.setSpecialOffer(SpecialOfferType.ShowButton)
  }

  const activator = <Observer>{() => {
    const { special_offer } = userInfoSore.data

    if (special_offer === SpecialOfferType.Hidden){
      return null
    }

    return <div className={"Polaris-ActionMenu-SecondaryAction relative"}>
      {special_offer === SpecialOfferType.ShowAll && <PolarisIndicator />}
      <Button variant={"secondary"} icon={GiftCardIcon} disclosure onClick={() => handleSpecialOfferClick(special_offer)}>
        {t("SpecialOffer")}
      </Button>
    </div>
  }}</Observer>

  // 联系额度翻倍
  const handleMessageDoubleQuota = (e:any) => {
    e && e.stopPropagation()
    showNewMessages(t("freeQuotaBanner.concatUsMsg"))
    setShowPopover(false)
  }
  // 联系Remove branding
  const handleDeleteBranding = (e:any) => {
    e && e.stopPropagation()
    showNewMessages(t("RemoveBranding"))
    setShowPopover(false)
  }

  return <>
    <Popover
      preferredAlignment={"right"}
      active={showPopover}
      activator={activator}
      autofocusTarget="first-node"
      onClose={() => setShowPopover(false)}
    >
      <div className={styles.actionWrapper}>
        <ActionList
          actionRole="menuitem"
          items={[
            {
              icon: DuplicateIcon,
              content: t("SpecialOfferList1.title"),
              helpText: <Trans ns="dashboard" i18nKey="SpecialOfferList1.helpText">
                {/* @ts-ignore*/}
                <Link removeUnderline onClick={handleMessageDoubleQuota} />
              </Trans>,
              onAction() {
                togglePopup(PopupType.FreeQuota)
              },
            },
            {
              icon: IconsFilledIcon,
              content: t("SpecialOfferList2.title"),
              helpText: <Trans ns="dashboard" i18nKey="SpecialOfferList2.helpText">
                {/* @ts-ignore*/}
                <Link removeUnderline onClick={handleDeleteBranding} />
              </Trans>,
              onAction() {
                togglePopup(PopupType.RemoveBrand)
              },
            },
            {
              icon: ShareIcon,
              content: t("SpecialOfferList3.title"),
              helpText: t("SpecialOfferList3.helpText"),
              onAction() {
                togglePopup(PopupType.RecommendFriends)
              },
            },
            {
              icon: HeartIcon,
              content: t("SpecialOfferList4.title"),
              helpText: t("SpecialOfferList4.helpText"),
              onAction() {
                togglePopup(PopupType.PartnerFriendly)
              },
            },
            {
              icon: XIcon,
              content: t("dismiss"),
              destructive: true,
              onAction() {
                togglePopup(PopupType.Dismiss)
              },
            },
          ]}
        />
      </div>
    </Popover>


    <Modal
      open={popupStates.FreeQuota}
      onClose={() => togglePopup(PopupType.FreeQuota)}
      title={t("SpecialOfferList1.modalTitle")}
      primaryAction={{
        content: t("freeQuotaBanner.action"),
        onAction: () => {
          togglePopup(PopupType.FreeQuota)
          handleMessageDoubleQuota(null)
        },
      }}
    >
      <Modal.Section>
        {t("SpecialOfferList1.description")}
      </Modal.Section>
    </Modal>

    <Modal
      open={popupStates.RemoveBrand}
      onClose={() => togglePopup(PopupType.RemoveBrand)}
      title={t("SpecialOfferList2.modalTitle")}
      primaryAction={{
        content: t("freeQuotaBanner.action"),
        onAction: () => {
          togglePopup(PopupType.RemoveBrand)
          handleDeleteBranding(null)
        },
      }}
    >
      <Modal.Section>
        {t("SpecialOfferList2.description")}
      </Modal.Section>
    </Modal>

    <Modal
      open={popupStates.RecommendFriends}
      onClose={() => togglePopup(PopupType.RecommendFriends)}
      title={t("SpecialOfferList3.title")}
      secondaryActions={[{
        content: commonT("Close"),
        onAction: () => {
          togglePopup(PopupType.RecommendFriends)
        },
      }]}
    >
      <Modal.Section>
        {t("SpecialOfferList3.description")}
        <div className={"mt-1 flex gap-2 items-center"}>
          <div className={"w-[400px] max-w-[80%]"}>
            <TextField label={""} autoComplete={""} readOnly value={ShareUrl} />
          </div>

          <Tooltip content={commonT("CopyLink")} dismissOnMouseOut>
            <Button icon={ClipboardIcon} onClick={() => {
              const copyRes = copy(ShareUrl)

              toast(copyRes ? commonT("CopiedSuccessfully") : commonT("CopiedUnsuccessfully"), 2e3, !copyRes)
            }}
            />
          </Tooltip>

          <SharingIcons />

        </div>
      </Modal.Section>
    </Modal>
    <Modal
      open={popupStates.PartnerFriendly}
      onClose={() => togglePopup(PopupType.PartnerFriendly)}
      title={t("SpecialOfferList4.title")}
      secondaryActions={[{
        content: commonT("Close"),
        onAction: () => {
          togglePopup(PopupType.PartnerFriendly)
        },
      }]}
    >
      <Modal.Section>
        {text_message}
      </Modal.Section>
    </Modal>
    <Modal
      open={popupStates.Dismiss}
      onClose={() => togglePopup(PopupType.Dismiss)}
      title={t("SpecialOfferDismiss.title")}
      secondaryActions={[{
        content: commonT("Cancel"),
        onAction: () => {
          togglePopup(PopupType.Dismiss)
        },
      }]}
      primaryAction={{
        destructive: true,
        content: t("dismiss"),
        onAction: () => {
          togglePopup(PopupType.Dismiss)

          userInfoSore.setSpecialOffer(SpecialOfferType.Hidden)
        },
      }}
    >
      <Modal.Section>
        {t("SpecialOfferDismiss.description")}
      </Modal.Section>
    </Modal>
  </>
}

export default SpecialOffer
