import {
  Button,
  Link,
  SkeletonBodyText,
} from "@shopify/polaris"
import { SettingsIcon } from "@shopify/polaris-icons"
import React from "react"
import { Trans, useTranslation } from "react-i18next"

import ListCard from "@/pages/Settings/ShippingNotifications/components/ListCard"

export default function SkeletonNotifications() {
  const { t } = useTranslation("settings")
  
  return (
    <div className={"flex flex-col gap-4"}>
      <ListCard
        title={t("NotificationsToCustomers")}
        titleRight={<Button icon={SettingsIcon}>{t("SenderInfo")}</Button>}
        description={<Trans ns="settings" i18nKey="NotificationsToCustomersDesc">
          <Link url="https://docs.parcelpanel.com/shopify/notifications/parcelpanel-notifications/" removeUnderline external />
        </Trans>}
      >
        <div className={"mt-4"}>
          <SkeletonBodyText lines={13} />
        </div>
      </ListCard>

      <ListCard
        title={t("NotificationsToYourself")}
        titleRight={<Button icon={SettingsIcon}>{t("RecipientInfo")}</Button>}
        description={t("NotificationsToYourselfDesc")}
      >
        <div className={"mt-4"}>
          <SkeletonBodyText lines={13} />
        </div>
      </ListCard>
    </div>
  )
}
