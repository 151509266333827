import { BlockStack, EventListener, Modal, Text } from "@shopify/polaris"
import { ExportIcon, ExternalSmallIcon } from "@shopify/polaris-icons"
import { debounce } from "lodash-es"
import React, { useCallback, useEffect, useState } from "react"
import { Trans, useTranslation } from "react-i18next"

import { APICode } from "@/api/APICode"
import OrdersAPI from "@/api/orders"
import { AuthButton, NormalLink } from "@/component"
import env from "@/config/env"
import Permission from "@/enums/permission"
import { BetaFeature } from "@/models/IUserInfo"
import stores from "@/stores"
import { canIUseBetaFeature } from "@/utils/BetaFeature"
import toast from "@/utils/toast"

interface IExportModalProps {
  open: boolean
  exportCount: number
  exportEmail: string

  onClose(): void

  onExport(): void

  onCustomExport?(): void
}

export function ExportShipmentsControl() {
  const { t } = useTranslation("common")

  // 显示模式 图标 或 图标+文本
  const [condensed, setCondensed] = useState(true)

  const [exportShipmentsModalOpen, setExportShipmentsModalOpen] = useState(false)
  const [exportShipmentsButtonLoading, setExportShipmentsButtonLoading] = useState(false)
  const [exportEmail, setExportEmail] = useState("")


  useEffect(() => {
    handleResize()
  }, [])

  const handleResize = () => {
    // console.log('resize')
    /* 借鉴了 Shopify 的 resize 逻辑 */
    condensed !== (() => {
      if ("undefined" === typeof window) {
        return false
      }
      const e = window.matchMedia("(max-width: 650px)")

      return e.matches
    })() && setCondensed(!condensed)
  }

  const handleExportButtonClick = () => {
    setExportShipmentsButtonLoading(true)

    OrdersAPI.exportChesApi()
      .then(({ data: { data: { export_email } } }) => {
        setExportEmail(export_email)
        setExportShipmentsModalOpen(true)
      })
      .finally(() => setExportShipmentsButtonLoading(false))
  }

  let handleCustomExport

  if (canIUseBetaFeature(BetaFeature.CUSTOM_EXPORT_SHIPMENTS)) {
    handleCustomExport = () => {
      const query = new URLSearchParams({
        id_token: stores.commonStore.authToken ?? "",
        ...JSON.parse(JSON.stringify(stores.ordersStore.queryParams)),
      })

      window.open(`${env.base_api_url}/v2/shipments/custom_export?${query.toString()}`)
    }
  }

  /** 处理导出事件 */
  const handleExport = () => {
    const numberCountD = stores.ordersStore.itemsCount

    const params = {
      ...stores.ordersStore.queryParams,
      numberCountD,
    }

    return OrdersAPI.exportDataApi(params)
      .then(({ data: { code, msg, data } }) => {
        if (code !== APICode.OK) {
          return
        }
        const fileCon = data.file
        const { noDown } = data

        if (!noDown) {
          const blob = new Blob([fileCon])
          const fileName = "export_orders.csv"
          const linkNode = document.createElement("a")

          linkNode.download = data.filename ?? fileName  // a标签的download属性规定下载文件的名称
          linkNode.style.display = "none"
          linkNode.href = URL.createObjectURL(blob)  // 生成一个Blob URL
          document.body.appendChild(linkNode)
          linkNode.click()  // 模拟在按钮上的一次鼠标单击

          URL.revokeObjectURL(linkNode.href)  // 释放URL 对象
          document.body.removeChild(linkNode)
        } else {
          toast(msg, 2e3)
        }
      })
  }

  return (
    <>
      <ExportModal
        open={exportShipmentsModalOpen}
        exportCount={stores.ordersStore.itemsCount}
        exportEmail={exportEmail}
        onClose={() => setExportShipmentsModalOpen(false)}
        onExport={handleExport}
        onCustomExport={handleCustomExport}
      />
      <AuthButton
        permission={Permission.CSVExport}
        icon={ExportIcon}
        onClick={handleExportButtonClick}
        loading={exportShipmentsButtonLoading}
      >
        {condensed ? void 0 : t("Export")}
      </AuthButton>

      <EventListener
        event="resize"
        handler={debounce(handleResize, 100)}
      />
    </>
  )
}

function ExportModal({
  open,
  exportCount,
  exportEmail,
  onClose,
  onExport,
  onCustomExport,
}: IExportModalProps) {

  const [loading, setLoading] = useState(false)

  const handleExportClick = useCallback(async () => {
    // console.log({...stores.ordersStore.queryParams})
    setLoading(true)
    try {
      await onExport()
    } finally {
      setLoading(false)
    }
    onClose()
  }, [])

  const { t } = useTranslation(["orders", "common"])

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={t("orders:ExportShipmentsControl.title")}
      primaryAction={{ content: t("orders:ExportShipmentsControl.title"), loading, onAction: handleExportClick }}
      secondaryActions={[
        { content: t("common:Cancel"), onAction: onClose },
        ...(onCustomExport ? [{ content: "Custom export", icon: ExternalSmallIcon, onAction: onCustomExport }] : []),
      ]}
      sectioned
    >
      <BlockStack
        gap="300"
      >
        <p>
          <Trans
            ns="orders"
            i18nKey="ExportShipmentsControl.p1"
            values={{ count: exportCount }}
          >
            {/* @ts-ignore */}
            <Text
              as="span"
              fontWeight="semibold"
            />
          </Trans>
        </p>

        <p>{t("orders:ExportShipmentsControl.p3")} <Text
          as="span"
          fontWeight="semibold"
        >{exportEmail}</Text></p>

        <p>
          <Trans
            ns="orders"
            i18nKey="ExportShipmentsControl.p2"
          >
            Learn more about
            <NormalLink
              link="https://docs.parcelpanel.com/shopify/tracking-status/export-orders-data/"
              external
            >
              CSV table headers
            </NormalLink>
          </Trans>
        </p>
      </BlockStack>
    </Modal>
  )
}
