// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { flatten } from "flat"
import { cloneDeep, isEqual } from "lodash-es"
import { deepObserve, IDisposer } from "mobx-utils"

import EDDApi from "@/api/settings/preEDD"
import stores from "@/stores"
import toast from "@/utils/toast"

export let disposer: IDisposer | null = null
export let defaultOptions: any

/**
 * 设置初始值
 * @param options
 */
const setInitOptions = (options: any) => {
  defaultOptions = cloneDeep(options)
}


/**
 * 获取初始值配置
 */
const getInitOptions = () => {
  return defaultOptions
}

/**
 * 注册监听器
 */
const registerListener = () => {
  const { PrePurchaseEDDStore } = stores

  disposer = deepObserve(PrePurchaseEDDStore.widgetData, (change) => {
    if (change.type === "update" && change.observableKind === "object") {
      const base = flatten(defaultOptions)
      const now = flatten(PrePurchaseEDDStore.widgetData.localized_settings)

      const isChange = !isEqual(base, now)

      if (PrePurchaseEDDStore.widgetSaveBar.show !== isChange) {
        PrePurchaseEDDStore.setWidgetSaveBar("show", isChange)
      }
    }
  })
  return true
}

const destroyListener = () => {
  const { PrePurchaseEDDStore } = stores

  disposer && disposer()
  PrePurchaseEDDStore.setWidgetSaveBar("show", false)
  PrePurchaseEDDStore.setWidgetSaveBar("loading", false)
}

/**
 * 保存数据
 */
const saveConfig = ({ successText, errorText }: { successText: string, errorText: string }) => {
  const { PrePurchaseEDDStore } = stores

  PrePurchaseEDDStore.setWidgetSaveBar("loading", true)
  return new Promise((resolve, reject) => {
    EDDApi.setWidgetConfig({
      widget: { localized_settings: PrePurchaseEDDStore.widgetData.localized_settings },
    }).then(() => {
      destroyListener() // 销毁监听
      setInitOptions(PrePurchaseEDDStore.widgetData.localized_settings)
      registerListener() // 启动监听
      toast(successText, 2e3)
      resolve(true)
    })
      .catch((e) => {
        toast(errorText, 2e3, true)
        reject(e)
      })
      .finally(() => {
        PrePurchaseEDDStore.setWidgetSaveBar("loading", false)
      })
  })
}

/**
 * 放弃更改数据
 */
const discardConfig = () => {
  const { PrePurchaseEDDStore } = stores
  const defaultConfig = getInitOptions()

  destroyListener()// 先销毁监听器
  PrePurchaseEDDStore.setData("widgetData.localized_settings", defaultConfig)
  registerListener()// 后注册监听器
}

export default {
  destroyListener,
  discardConfig,
  getInitOptions,
  registerListener,
  saveConfig,
  setInitOptions,
}
