import { Icon, Tooltip } from "@shopify/polaris"
import { ClipboardIcon } from "@shopify/polaris-icons"
import copy from "copy-to-clipboard"
import { useTranslation } from "react-i18next"

import toast from "@/utils/toast"

interface CopyFiledProps {
  copyValue?: string
  children?: React.ReactNode | string
  toolTipText: string
}

export default function CopyFiled({ copyValue, children, toolTipText }: CopyFiledProps) {
  const { t } = useTranslation(["common"])

  const handleCopyClick = () => {
    if (copyValue) {
      copy(copyValue)
      toast(t("common:CopiedSuccessfully"))
    }
  }

  return (
    <div className="flex items-center cursor-pointer">
      {
        copyValue ? <div className={"relative"} style={{ zIndex: 2 }}>
          <Tooltip content={toolTipText} dismissOnMouseOut>
            <div onClick={handleCopyClick}>
              <Icon source={ClipboardIcon} tone={"base"} />
            </div>
          </Tooltip>
        </div> : null
      }
      {children || "-"}
    </div>
  )
}
