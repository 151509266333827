enum Permission {
  CSVExport = "CSVExport",
  CustomOrderStatus = "CustomOrderStatus",
  AutoTranslateTrackingDetails = "AutoTranslateTrackingDetails",
  EstimatedDeliveryTime = "EstimatedDeliveryTime",
  ProductRecommendations = "ProductRecommendations",
  ProductDiscount = "ProductDiscount",
  ParcelPanelShippingNotifications = "ParcelPanelShippingNotifications",
  IntegrateAppKlaviyo = "IntegrateAppKlaviyo",
  IntegrateAppPayPal = "IntegrateAppPayPal",
  IntegrateAppYotpo = "IntegrateAppYotpo",
  WebhookIntegration = "WebhookIntegration",
  APIIntegration = "APIIntegration",
  IntegrateAppOmnisend = "IntegrateAppOmnisend",
  IntegrateAppGorgias = "IntegrateAppGorgias",
  AdvancedAnalytics = "AdvancedAnalytics",
  ProductRecommendationAndDiscountUpsell = "ProductRecommendationAndDiscountUpsell",
  RemoveBranding = "RemoveBranding",
  PreEDD = "PreEDD"
}

export default Permission
