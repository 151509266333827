import {
  CollectionResult, ProductAndCollectionInitResult, ProductAndCollectionListResult,
  ProductResult,
  ShopifyGraphqlFetchResponse,
} from "@/api/shopify-graphql/shopify-graphql.type"

export const shopifyGraphqlFetch = async <T>(query: string, variables?: object): Promise<ShopifyGraphqlFetchResponse<T>> => {
  // TODO 判断是否为超管后台模式，如果是则调用我们能自己的 GraphQL Proxy API
  const res = await fetch("shopify:admin/api/2024-07/graphql.json", {
    method: "POST",
    body: JSON.stringify({
      query,
      variables,
    }),
  })

  return {
    status: res.status,
    data: (await res.json()).data,
  }
}

export default {
  getProduct(id: string) {
    return shopifyGraphqlFetch(
      // language=GraphQL
      `
        query GetProduct($id: ID!) {
          product(id: $id) {
            title
          }
        }`,
      { id: `gid://shopify/Product/${id}` },
    )
  },

  getPublishedCheckoutProfile() {
    return shopifyGraphqlFetch(
      // language=GraphQL
      `query checkoutProfiles {
        checkoutProfiles(first: 1, query: "is_published:true") {
          edges {
            node {
              id
              name
              isPublished
              typOspPagesActive
            }
          }
        }
      }`,
    )
  },

  searchProducts(query?: string, after: string|null = null) {
    return shopifyGraphqlFetch<ProductResult>(
      `
      query searchProducts($query: String!, $after: String) {
        products(query: $query, first: 20, after: $after) {
          pageInfo {
            hasNextPage
            endCursor
          }
          nodes {
            id
            title
            featuredMedia {
              __typename
              preview {
                __typename
                image {
                  url(transform: { maxWidth: 200, maxHeight: 200 })
                }
              }
            }
          }
        }
      }`,
      { query, after },
    )
  },

  searchCollections(query?: string, after: string|null = null){
    return shopifyGraphqlFetch<CollectionResult>(
      `
      query searchCollections($query: String!, $after: String) {
        collections(query: $query, first: 20, after: $after) {
          pageInfo {
            hasNextPage
            endCursor
          }
          nodes {
            id
            title
            image {
              url(transform: { maxWidth: 200, maxHeight: 200 })
            }
          }
        }
      }`,
      { query, after },
    )
  },


  // 初始化请求
  initProductsAndCollections(query: string = "", after: string|null = null){
    return shopifyGraphqlFetch<ProductAndCollectionInitResult>(
      `
      query searchCollections($query: String!, $after: String) {
        products(query: $query, first: 20, after: $after) {
            pageInfo {
              hasNextPage
              endCursor
            }
            nodes {
              id
              title
              featuredMedia {
                __typename
                preview {
                  __typename
                  image {
                    url(transform: { maxWidth: 200, maxHeight: 200 })
                  }
                }
              }
            }
        }
        collections(query: $query, first: 20, after: $after) {
          pageInfo {
            hasNextPage
            endCursor
          }
          nodes {
            id
            title
            image {
              url(transform: { maxWidth: 200, maxHeight: 200 })
            }
          }
        }
      }`,
      { query, after },
    )
  },

  // GraphQL 同时搜索 Products 及 Collection 列表
  filteredProductsAndCollections(query?: string, productsAfter: string|null = null, collectionsAfter: string|null = null) {
    return shopifyGraphqlFetch(
      `
      query FilteredProductsAndCollections($query: String!, $productsAfter: String, $collectionsAfter: String) {
        filteredProducts: products(first: 250, after: $productsAfter, query: $query) {
          pageInfo {
            hasNextPage
            endCursor
            __typename
          }
          nodes {
            id
          }
        }
        filteredCollections: collections(first: 250, after: $collectionsAfter, query: $query) {
          pageInfo {
            hasNextPage
            endCursor
            __typename
          }
          nodes {
            id
          }
        }
      }`,
      { query, productsAfter, collectionsAfter },
    )
  },

  // GraphQL 同时搜索 Products 及 Collection 列表
  getProductAndCollectionList(ids?: string[]) {
    return shopifyGraphqlFetch<ProductAndCollectionListResult>(
      `query ProductAndCollectionList($ids: [ID!]!) {
                nodes(ids: $ids) {
                  ...CollectionFragment
                  ...ProductFragment
                  __typename
                }
              }
              
              fragment CollectionFragment on Collection {
                id
                title
                image {
                  url(transform: { maxWidth: 200, maxHeight: 200 })
                }
              }
              
              fragment ProductFragment on Product {
                id
                title
                featuredMedia {
                  __typename
                  preview {
                    __typename
                    image {
                      url(transform: {maxWidth: 80, maxHeight: 80})
                    }
                  }
                }
              }`,
      { ids },
    )
  },
}
