import { Link } from "@shopify/polaris"
import React from "react"
import { Trans } from "react-i18next"
import { useIntercom } from "react-use-intercom"

interface IProps {
  pathname: string | "/" | "/tracking-page" | "/orders"
}

// export default function Footer({ pathname }: IProps) {
//   const { t } = useTranslation(["footer", "common"])
//   let pathKey = pathname
//
//   /* tracking-page 特殊处理 */
//   if (pathname.startsWith("/tracking-page")) {
//     pathKey = "/tracking-page"
//   } else if (pathname.startsWith("/analytics")) {
//     pathKey = "/analytics"
//   }
//
//   switch (pathKey) {
//     case "/":
//       return (
//         <Trans ns="footer" i18nKey="dashboard">
//           Learn more about <Link external url={"https://docs.parcelpanel.com/shopify/getting-started/"}>Getting
//           started</Link>
//         </Trans>
//       )
//
//     case "/orders":
//       return (
//         <Trans ns="footer" i18nKey="orders">
//           Learn more about <Link external url={"https://docs.parcelpanel.com/shopify/tracking-status/"}>Getting
//           started</Link>
//         </Trans>
//       )
//
//     case "/tracking-page":
//       return (
//         <Trans ns="footer" i18nKey="tracking_page">
//           Learn more about <Link external url={"https://docs.parcelpanel.com/shopify/tracking-page/"}>Tracking
//           page</Link> and <Link external
//             url={"https://docs.parcelpanel.com/shopify/getting-started/add-the-tracking-page/"}
//           >How
//           to add it to your store</Link>
//         </Trans>
//       )
//
//     case "/settings":
//       return (
//         <Trans ns="footer" i18nKey="settings">
//           Learn more about <Link external
//             url={"https://docs.parcelpanel.com/shopify/notifications/"}
//           >Notifications</Link>
//         </Trans>
//       )
//
//     case "/analytics":
//       return (
//         <Trans ns="footer" i18nKey="analytics">
//           Learn more about <Link external url={"https://docs.parcelpanel.com/shopify/analytics/"}>Getting started</Link>
//         </Trans>
//       )
//
//     case "/integration":
//       return (
//         <Trans ns="footer" i18nKey="integration">
//           Learn more about <Link external url={"https://docs.parcelpanel.com/shopify/integration/"}>Getting
//           started</Link>
//         </Trans>
//       )
//
//     case "/billing":
//       return (
//         <Trans ns="footer" i18nKey="billing">
//           Learn more about <Link external url={"https://docs.parcelpanel.com/shopify/plan-billing/"}>Getting
//           started</Link>
//         </Trans>
//       )
//
//     case "/protection":
//       return (
//         <Trans ns="footer" i18nKey="delivery_setting">
//           Learn more about <Link external url={"https://docs.parcelpanel.com/shopify/shipping-protection/learn-more-about-worry-free-delivery/"}>
//           Worry-Free Delivery
//           </Link>
//         </Trans>
//       )
//
//     case "/protection/setting":
//       return (
//         <Trans ns="footer" i18nKey="delivery_setting">
//           Learn more about <Link external url={"https://docs.parcelpanel.com/shopify/shipping-protection/learn-more-about-worry-free-delivery/"}>
//           Worry-Free Delivery
//           </Link>
//         </Trans>
//       )
//
//     case "/protection/covers":
//       return (
//         <Trans ns="footer" i18nKey="delivery_covers">
//           Learn more about <Link external url={"https://docs.parcelpanel.com/shopify/shipping-protection/learn-more-about-worry-free-delivery/"}>
//           Worry-Free Delivery
//           </Link>
//         </Trans>
//       )
//
//     case "/protection/claims":
//       return (
//         <Trans ns="footer" i18nKey="delivery_claims">
//           Learn more about <Link external url={"https://docs.parcelpanel.com/shopify/shipping-protection/learn-more-about-worry-free-delivery/"}>
//           Worry-Free Delivery
//           </Link>
//         </Trans>
//       )
//
//     case "/protection/transactions":
//       return (
//         <Trans ns="footer" i18nKey="delivery_transactions">
//           Learn more about <Link external url={"https://docs.parcelpanel.com/shopify/shipping-protection/learn-more-about-worry-free-delivery/"}>
//           Worry-Free Delivery Transactions
//           </Link>
//         </Trans>
//       )
//
//     default:
//       return <FooterNeedAnyHelp />
//   }
// }

export default function Footer(props: IProps){
  return <Trans ns="footer" i18nKey="footerNew">
    <Link external url={"https://www.parcelpanel.com/"}>ParcelPanel</Link>
    <Link external url={"https://www.channelwill.com/"}>Channelwill</Link>
  </Trans>
}

function FooterNeedAnyHelp() {
  const { showNewMessages } = useIntercom()

  return (
    <Trans ns="footer" i18nKey="footer">
      Need any help? <Link onClick={() => showNewMessages("")} removeUnderline>Contact us</Link>
    </Trans>
  )
}
