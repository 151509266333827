import { Text } from "@shopify/polaris"
import React from "react"

interface TitleGroupProps {
  title?: React.ReactNode | string
  titleRight?: React.ReactNode | string
  subTitle?: React.ReactNode | string
  children?: React.ReactNode | string
  className?: string
}

const TitleGroup = ({
  title, subTitle, children, className, titleRight,
}:TitleGroupProps) => {
  return <div className={className}>
    <div className={"flex justify-between items-center"}>
      {title && <Text as="h3" variant={"headingSm"}>{title}</Text>}
      {titleRight}
    </div>
    {
      subTitle && <div className="mt-1">
        <Text as="p" variant={"bodyMd"} tone={"subdued"}>
          {subTitle}
        </Text>
      </div>
    }
    {
      children && <div className="mt-1">{children}</div>
    }
  </div>
}

export default TitleGroup
