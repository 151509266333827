import {
  BaseResponse,
  CartSettingApi,
  CheckWidgetApi, ClaimList, ContractList,
  InfoApi, ListData, OverviewApi, ParamsApi, SysConfigA, TransactionsListApi,
} from "@/pages/DeliveryGuarantee/Api/type"

import request from "../utils/request"

export default {

  /**
   * 获取信息
   */
  getInfo() {
    return request.get<BaseResponse<InfoApi>>("/api/v1/getInfo")
  },

  /**
   * 预览数据 /api/v1/overviewData
   */
  getOverviewData(claimAt:string) {
    return request.get<BaseResponse<OverviewApi>>("/api/v1/overviewData", { params: { claimAt } })
  },

  /**
   * 获取小部件配置
   */
  getCartSetting() {
    return request.get<BaseResponse<CartSettingApi>>("/api/v1/getCartSetting")
  },

  /**
   * 申请审核接口 /api/v1/applicationAudit
   */
  setApplicationAudit() {
    return request.post<BaseResponse<InfoApi>>("/api/v1/applicationAudit")
  },

  /**
   * 审核结果以查看设置 /api/v1/setViewAuditRet
   */
  setViewAuditRet() {
    return request.post<BaseResponse<InfoApi>>("/api/v1/setViewAuditRet")
  },

  /**
   * 小部件设置 除去总开关 /api/v1/setSpecial
   */
  setSpecial(param: {
    defaultDisplayStatus:number,
    fulfillmentType:number
  }) {
    return request.post<BaseResponse<CartSettingApi>>("/api/v1/setSpecial", param)
  },

  /**
   * 小部件开关开启 /api/v1/setSpecial
   */
  setWidgetOn() {
    return request.post<BaseResponse<CartSettingApi>>("/api/v1/setWidgetOn")
  },

  /**
   * 小部件开关 关闭 /api/v1/setWidgetOff
   */
  setWidgetOff(param: string) {
    return request.post<BaseResponse<CartSettingApi>>("/api/v1/setWidgetOff", param)
  },

  /**
   * 检测小部件主题内是否开启 /api/v1/checkWidget
   */
  detectionCheckWidget() {
    return request.get<BaseResponse<CheckWidgetApi>>("/api/v1/checkWidget")
  },

  /**
   * 设置手动或自动发布 /api/v1/setAutoPublish
   */
  setAutoPublish(param:{autoPublic:number}) {
    return request.post<BaseResponse<ParamsApi>>("/api/v1/setAutoPublish", param)
  },

  /**
   * 保单列表 /api/v1/contract
   */
  getContract(param:ParamsApi) {
    return request.post<BaseResponse<ListData<ContractList>>>("/api/v1/contract", param)
  },

  /**
   * 理赔列表 /api/v1/claim
   */
  getClaim(param:ParamsApi) {
    return request.post<BaseResponse<ListData<ClaimList>>>("/api/v1/claim", param)
  },

  /**
   * 保费账单列表 /api/v1/transactions
   */
  getTransactions(param:ParamsApi) {
    return request.post<BaseResponse<ListData<TransactionsListApi>>>("/api/v1/transactions", param)
  },

  /**
   * 设置支付方式 手动/自动 /api/v1/setPayMethod
   */
  setPayMethod(param:{payNumber?:string, payMethod:number}) {
    return request.post("/api/v1/setPayMethod", param)
  },

  /**
   * 获取公共配置 /api/v1/getSysConfig
   */
  getSysConfig() {
    return request.get<BaseResponse<ListData<SysConfigA>>>("/api/v1/getSysConfig")
  },

  /**
   * 设置公共配置  /api/v1/setSysConfig
   */
  setSysConfig(param:{plusBlock?:number}) {
    return request.post("/api/v1/setSysConfig", param)
  },

  /**
   * 获取账单Excel表格 /api/v1/downloadTransact
   */
  getDownloadTransact(billId:string) {
    return request.get<BaseResponse<{url:string}>>("/api/v1/downloadTransact", { params: { billId } })
  },


  /**
   * 未支付账单数量 /api/v1/transactUnpaidCount
   */
  getTransactUnpaidCount() {
    return request.get<BaseResponse<ListData<{total:string}>>>("/api/v1/transactUnpaidCount")
  },

  /**
   * 设置公共配置  /api/v1/setPlusWidgetOn
   */
  setPlusWidgetOn() {
    return request.post<BaseResponse<{isEnable:boolean}>>("/api/v1/setPlusWidgetOn")
  },


}
