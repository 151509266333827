import { ContextualSaveBar as ContextualSaveBarActions } from "@shopify/app-bridge/actions"
import { isShopifyEmbedded } from "@shopify/app-bridge-utils"
import { ContextualSaveBar } from "@shopify/polaris"
import dayjs from "dayjs"
import { cloneDeep } from "lodash-es"
import { observer } from "mobx-react"
import React, { Component, RefObject } from "react"
import { TFunction, useTranslation } from "react-i18next"
import { useIntercom } from "react-use-intercom"
import { IntercomContextValues } from "react-use-intercom/src/types"

import { APICode } from "@/api/APICode"
import { BaseResponse } from "@/api/base"
import trackingPage from "@/api/tracking-page"
import TPAPI from "@/api/tracking-page/index"
import { Container, IRouter, TabPage, withRouter } from "@/component"
import UnsavedPrompt from "@/component/UnsavedPrompt"
import {
  FeaturesText,
  ITrackingPageOptions,
  ProductRecommendIntent,
  Status,
  Translations,
} from "@/models/ITrackingPageOptions"
import { initModulesStore, registerModuleListener } from "@/pages/TrackingPage/EstimatedDeliveryTime/listener"
import stores from "@/stores"
import AppBridge from "@/utils/appBridge"
import { deepDiffBetweenObjects } from "@/utils/ObjectUtils"
import toast from "@/utils/toast"
import TrackingPageUtils from "@/utils/TrackingPageUtils"

// 原始配置信息
let initOptions: ITrackingPageOptions

interface IState {
  proRecomRegionalTipShow: boolean
  proDiscountRegionalTipShow: boolean
}

interface IProps {
  router?: IRouter
  intercom: IntercomContextValues
  t: TFunction<"tracking_page" | "common">
}

export default function TrackingPage() {
  const intercom = useIntercom()
  const { t } = useTranslation("tracking_page")

  return <_TrackingPage intercom={intercom} t={t} />
}


@withRouter
@observer
class _TrackingPage extends Component<IProps, IState> {
  state: IState = {
    // msg: i18next.t("SavedSuccess", { ns: "common" }),

    proDiscountRegionalTipShow: false,
    proRecomRegionalTipShow: false,
  }
  private readonly PRUPositionRef: RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>()

  constructor(props: any) {
    super(props)

    type locationState = {
      proRecom: boolean
      proDiscount: boolean
    } | undefined

    this.state.proRecomRegionalTipShow = (this.props.router?.location.state as locationState)?.proRecom || false
    this.state.proDiscountRegionalTipShow = (this.props.router?.location.state as locationState)?.proDiscount || false

    if (this.state.proRecomRegionalTipShow || this.state.proDiscountRegionalTipShow) {
      // 清空 state，防止二次渲染时携带了 高亮参数
      this.props.router?.navigate({ ...this.props.router?.location }, { replace: true })
    }
  }


  public componentDidMount() {

    this.getData()
    this.getProductCollection()
  }

  render() {
    const { t } = this.props
    const { trackingPageStore, commonStore, eddModulesStore, userInfoSore } = stores
    const loading = trackingPageStore.loadedDataTime === 0
    const handleSaveAction = () => {
      const diffOptions = getDiffOptions()
      const data: Partial<ITrackingPageOptions> = {}

      Object.keys(diffOptions)
        .map(key => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          data[key] = cloneDeep(trackingPageStore[key])
          if (key === "customOrderStatus") {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            data[key] = data[key]?.ordered.children || []
          }
        })

      commonStore.setContextualSaveBar("loading", true)

      // if (data.proRecommendAndUpsell?.productRecommendations) {
      //   const productRecommendations = trackingPageStore.filterProductRecommendationsOptions()
      //
      //   if (productRecommendations) {
      //     data.proRecommendAndUpsell.productRecommendations = productRecommendations
      //   }
      // }

      if (data.proRecommendAndUpsell?.productRecommendations.intent === ProductRecommendIntent.SpecificCollection
        && data.proRecommendAndUpsell?.productRecommendations.collect === 0){
        trackingPageStore?.setRecommendationSelectCollectionError(true)
        commonStore.setContextualSaveBar("loading", false)
        return
      }

      if (data.translateTrackingDetailedInfo) {
        data.translateTrackingDetailedInfo = trackingPageStore.removeAnotherHandle() || []
      }

      if (
        (data.displayOption &&
          (isNaN(Number(data.displayOption?.themeContainerWidth)) || data.displayOption?.themeContainerWidth === 0)) ||
        (data.customOrderInput && data.customOrderInput.days === "0")
      ) {
        toast(t("SavedFailed", { ns: "common" }), 2e3, true)
        commonStore.setContextualSaveBar("loading", false)
        return
      }

      if (data.post_purchase_edd?.non_business_day_periods?.length) {
        data.post_purchase_edd.non_business_day_periods = trackingPageStore.clearNonBusinessDayPeriods()
      }

      // 空文本检测
      if (data.translations) {
        const hasEmptyValue = Object.entries(data.translations)
          .some(([lang, trans]) => {

            return Object.entries(trans)
              .some(([key, value]) => {
                if (key === "features_text") {
                  const featureText = value as FeaturesText

                  const isEmptyCustomStatus = featureText.custom_status
                    .some(({ status, info }) => status?.trim() === "" || info?.trim() === "")

                  return (
                    isEmptyCustomStatus
                    || featureText.additional_text_above?.trim() === ""
                    || featureText.additional_text_above?.trim() === ""
                    || featureText.custom_tracking_info?.trim() === ""
                  )
                }

                return (value as string).trim() === ""
              })
          })

        if (hasEmptyValue) {
          toast(t("SavedFailed", { ns: "common" }), 2e3, true)
          commonStore.setContextualSaveBar("loading", false)
          return
        }
      }

      TPAPI.save(data)
        .then(({ data: response }) => {
          toast(
            response.msg || t("ServerError", { ns: "common" }),
            2e3,
            response.code !== APICode.OK,
          )

          if (response.code === APICode.OK) {
            const { translations, translations_config } = response.data
            const recoverOptions = {
              translations,
              translations_config,
            }

            filterEmptyFeatureTextTrans(recoverOptions)

            // 修改初始值
            const newInitOptions = {
              ...cloneDeep(stores.trackingPageStore),
              ...recoverOptions,
            }

            TrackingPageUtils.setInitOptions(newInitOptions)
            trackingPageStore.setBaseOptions(recoverOptions)

            const { trackPageUrl } = stores.trackingPageStore

            // 更新 track_url
            stores.userInfoSore.setDataSingle("last_track_url", `${stores.userInfoSore.data.store_real_name}${trackPageUrl}`)

            commonStore.setContextualSaveBar("show", false)

            eddModulesStore.setSaveBtnActive(false)

            window.onbeforeunload = null
          }
        })
        .catch((reason) => {
          toast(
            t("SavedFailed", { ns: "common" }),
            2e3,
            true,
          )
        })
        .finally(() => {
          commonStore.setContextualSaveBar("loading", false)
        })
    }

    const handleDiscardAction = () => {
      commonStore.setContextualSaveBar("loading", false)
      commonStore.setContextualSaveBar("show", false)

      // 还原回初始值
      TrackingPageUtils.destroyListener()// 先销毁监听器
      trackingPageStore.setBaseOptions(TrackingPageUtils.getInitOptions())// 然后还原
      // 初始化edd module config
      if (!eddModulesStore.saveBtnActive) {
        initModulesStore()
      }
      TrackingPageUtils.registerListener()// 最后再注册监听器

      stores.userInfoSore.setDataSingle("showPPBranding", !trackingPageStore.brandingConfig?.isRemovedBranding)
    }

    const saveBar = commonStore.contextualSaveBar

    if (isShopifyEmbedded() && !window.isFromAdmin) {
      const ShopifyContextualSaveBar = ContextualSaveBarActions.create(AppBridge.app, {
        saveAction: {
          label: t("Save", { ns: "common" }),
          disabled: false,
          loading: false,
        },
        discardAction: {
          label: t("Discard", { ns: "common" }),
          disabled: false,
          loading: false,
          discardConfirmationModal: false,
        },
        leaveConfirmationDisable: true,
      })

      ShopifyContextualSaveBar.subscribe(ContextualSaveBarActions.Action.DISCARD, () => {
        handleDiscardAction()
        ShopifyContextualSaveBar.dispatch(ContextualSaveBarActions.Action.HIDE)
      })
      ShopifyContextualSaveBar.subscribe(ContextualSaveBarActions.Action.SAVE, () => {
        commonStore.setContextualSaveBar("loading", true)
        handleSaveAction()
      })
      ShopifyContextualSaveBar
        .set({
          saveAction: { loading: saveBar.loading },
        })
        .dispatch(commonStore.contextualSaveBar.show || commonStore.contextualSaveBar.loading ? ContextualSaveBarActions.Action.SHOW : ContextualSaveBarActions.Action.HIDE)
    }

    return (
      <div>
        <UnsavedPrompt
          title={t("LeavePageTitle", { ns: "common" })}
          message={t("LeavePageMessage", { ns: "common" })}
          secondaryLabel={t("Stay", { ns: "common" })}
          when={commonStore.contextualSaveBar.show && !commonStore.contextualSaveBar.unlocking}
          confirm={handleDiscardAction}
          isTrackingPage
          isShopifyEmbedded={isShopifyEmbedded() && !window.isFromAdmin}
        />

        <Container id="Page-TrackingPage" className={"mt-0"} maxWidth={"1036px"} fullWidth={false}>
          {/* 吸顶保存条 */}
          {
            (!isShopifyEmbedded() || window.isFromAdmin) && !loading && commonStore.contextualSaveBar.show && (
              <ContextualSaveBar
                message={t("UnsavedChanges", { ns: "common" })}
                fullWidth
                alignContentFlush
                saveAction={{
                  content: t("Save", { ns: "common" }),
                  disabled: commonStore.contextualSaveBar.disabled,
                  loading: commonStore.contextualSaveBar.loading,
                  onAction: handleSaveAction,
                }}
                discardAction={{
                  content: t("Discard", { ns: "common" }),
                  onAction: handleDiscardAction,
                }}
              />
            )
          }

          {/* 这里是新开的TrackingPage*/}
          <TabPage loading={loading} />

        </Container>
      </div>
    )
  }

  /* topBanner() {
    const {t} = this.props
    const last_track_url = stores.userInfoSore.data.last_track_url
    const contactUS = () => {
      const text = !stores.userInfoSore.showRemoveBrandingBanner ? '' : 'Hi support, I would like to remove ParcelPanel branding.'
      this.props.intercom.showNewMessages(text)
    }

    return (<>
      <Banner
        title={t('CustomizeTrackingPageBanner.title')}
        action={{content: t('preview'), url: `https://${last_track_url}?nums=1234&preview=parcelpanel`, external: true}}
        secondaryAction={{
          content: !stores.userInfoSore.showRemoveBrandingBanner
                       ? t('ContactUs', {ns: 'common'})
                       : t('ContactUsRemoveBranding'),
          onAction: contactUS,
        }}
        status="info"
      >
        <p>
          {t('CustomizeTrackingPageBanner.content')}</p>
      </Banner>
    </>)
  }*/

  getProductCollection() {
    const { productCollection } = stores

    trackingPage.productCollects()
      .then(({ data: response }) => {
        const { data } = response

        const productCollections = [{
          label: "Select...",
          value: "0",
        }]

        data.map((item: { title: string; id: number }) => {
          productCollections.push({
            label: item.title,
            value: `${item.id}`,
          })
        })

        productCollection.setProductCollection(productCollections)

      })

  }

  getData() {
    const { trackingPageStore } = stores

    // 获取用户配置信息
    TPAPI.baseInfo()
      .then(({ data: response }) => {
        interface Response extends BaseResponse {
          data: ITrackingPageOptions
        }

        const res: Response = response

        if (res.code === APICode.OK) {
          initOptions = res.data

          filterEmptyFeatureTextTrans(initOptions)

          TrackingPageUtils.setInitOptions(initOptions)
          trackingPageStore.setBaseOptions(initOptions)
          trackingPageStore.setLoadedDataTime(dayjs()
            .unix())
          TrackingPageUtils.registerListener()

          initModulesStore()

          registerModuleListener()
        }

        if (this.state.proDiscountRegionalTipShow || this.state.proRecomRegionalTipShow) {
          setTimeout(() => {
            this.PRUPositionRef.current?.scrollIntoView({
              behavior: "smooth",
              block: "start",
            })
          }, 500)
        }
      })
  }
}

/**
 * 清理 translations 空文本属性
 */
function filterEmptyFeatureTextTrans(initOptions: Pick<ITrackingPageOptions, "translations" | "translations_config">) {
  if (!initOptions.translations) {
    return
  }

  const primaryLocale = initOptions.translations_config?.primary_locale || "en"
  const _primaryTransFeaturesText = initOptions.translations[primaryLocale].features_text as FeaturesText

  const isEmptyAdditionalTextAbove = (_primaryTransFeaturesText.additional_text_above === "")
  const isEmptyAdditionalTextBelow = (_primaryTransFeaturesText.additional_text_below === "")
  const isEmptyCustomTrackingInfo = (_primaryTransFeaturesText.custom_tracking_info === "")
  const customStatusEmptyData = _primaryTransFeaturesText.custom_status.map(({ info, status }) => ({
    status: status === "",
    info: info === "",
  }))

  const langs = Object.keys(initOptions.translations)

  langs.forEach((lang) => {
    const featuresText: any = {}

    if (!isEmptyAdditionalTextAbove) {
      featuresText.additional_text_above = (initOptions.translations as Translations)[lang].features_text.additional_text_above
    }
    if (!isEmptyAdditionalTextBelow) {
      featuresText.additional_text_below = (initOptions.translations as Translations)[lang].features_text.additional_text_below
    }
    if (!isEmptyCustomTrackingInfo) {
      featuresText.custom_tracking_info = (initOptions.translations as Translations)[lang].features_text.custom_tracking_info
    }
    featuresText.custom_status = customStatusEmptyData.map(({ info, status }, i) => {
      if (info && status) {
        return
      }

      const statusData: any = {}

      if (!info) {
        statusData.info = (initOptions.translations as Translations)[lang].features_text.custom_status[i].info
      }
      if (!status) {
        statusData.status = (initOptions.translations as Translations)[lang].features_text.custom_status[i].status
      }

      return statusData
    })
      .filter(_ => _) as Status[]

    (initOptions.translations as Translations)[lang].features_text = featuresText
  })
}

/**
 * 获得差异配置信息
 */
export function getDiffOptions(): Partial<ITrackingPageOptions> {
  return deepDiffBetweenObjects(stores.trackingPageStore.options, TrackingPageUtils.getInitOptions())
}
