import { Grid, Layout, LegacyCard, SkeletonBodyText, SkeletonDisplayText, TextContainer } from "@shopify/polaris"
import React from "react"

export default function SkeletonAnalytics() {
  return (
    <>
      <Layout>
        <Layout.Section>
          <div className={"mb-3"}>
            <SkeletonDisplayText size="large" />
          </div>

          <div className={"mt-3 mb-3"}>
            <Grid gap={{ xs: "1rem", sm: "1rem", md: "1rem", lg: "1rem", xl: "1rem" }}>
              <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 3, lg: 4, xl: 4 }}>
                <LegacyCard sectioned>
                  <SkeletonBodyText lines={4} />
                </LegacyCard>
              </Grid.Cell>
              <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 3, lg: 4, xl: 4 }}>
                <LegacyCard sectioned>
                  <SkeletonBodyText lines={4} />
                </LegacyCard>
              </Grid.Cell>
              <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 3, lg: 4, xl: 4 }}>
                <LegacyCard sectioned>
                  <SkeletonBodyText lines={4} />
                </LegacyCard>
              </Grid.Cell>
            </Grid>
          </div>

          <LegacyCard sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={20} />
            </TextContainer>
          </LegacyCard>

          <LegacyCard sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={20} />
            </TextContainer>
          </LegacyCard>

        </Layout.Section>
      </Layout>
    </>
  )
}
