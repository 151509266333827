import { LegacyCard, Page, SkeletonTabs } from "@shopify/polaris"
import { useTranslation } from "react-i18next"

import { Container, SpinnerContainer } from "@/component"

export default function SkeletonOrders() {
  const { t } = useTranslation("menu")

  return (
    <Container id="Page-Orders-Skeleton" fullWidth>
      <Page title={t("orders")}></Page>
      <LegacyCard>
        <SkeletonTabs />
        <SpinnerContainer loading>
          <div style={{ height: "70vh" }} />
        </SpinnerContainer>
      </LegacyCard>
    </Container>
  )
}
