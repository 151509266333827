import i18n from "i18next"
import { cloneDeep, isEmpty, set } from "lodash-es"
import { action, computed, makeObservable, observable } from "mobx"

import settingsApi from "@/api/settings"
import {
  CheckoutSetting_Checkout,
  CheckoutSetting_Script, transNewText, transText,
} from "@/api/settings/type/emailConfig"
import { languageList } from "@/pages/Settings/EmailEdit/type"
import stores from "@/stores"
import { deepDiffBetweenObjects } from "@/utils/ObjectUtils"

export default class SettingsPageStore {
  @observable isScriptWidget = true
  @observable locale =  "en"
  /**
   * Script版本的Order Status widget 配置, 老版本
   */
  @observable OrderStatusWidgetDataScript: CheckoutSetting_Script = {
    "enabled": true,
    "display_type": "LINK",
    "primary_locale": "",
    "shop_locales": [],
    "translations": {
      "en": {
        "link": "",
        "button": "",
      },
    },
    "default_translations": {
      "en": {
        "link": "",
        "button": "",
      },
    },
  }
  /**
   * Checkout版本的Order Status widget 配置
   */
  @observable OrderStatusWidgetDataCheckout: CheckoutSetting_Checkout = {
    "primary_locale": "",
    "shop_locales": [],
    "translations": {
      "en": {
        "title": "",
        "description": "",
        "track": "",
      },
    },
    "default_translations": {
      "en": {
        "title": "",
        "description": "",
        "track": "",
      },
    },
  }

  constructor() {
    makeObservable(this)
  }

  @computed
  get optionsScript(): object {
    return cloneDeep(this.OrderStatusWidgetDataScript)
  }

  @computed
  get optionsCheckout(): object {
    return cloneDeep(this.OrderStatusWidgetDataCheckout)
  }

  @action.bound
  getLanguageList(defaultText: string): { label: string, value: string }[] {
    const { shop_locales, primary_locale } = this.isScriptWidget
      ? this.OrderStatusWidgetDataScript
      : this.OrderStatusWidgetDataCheckout

    return shop_locales.map(locale => {
      const target = languageList.find(item => item.value === locale) as { name: string, value: string }

      return { label: primary_locale === locale ? `${target.name} (${defaultText})` : target.name, value: target.value }
    })
  }

  // 获取script的翻译文本
  @computed
  get translationTextsScript(): transText {
    const { translations } = this.OrderStatusWidgetDataScript

    return translations[this.locale || "en"] || {}
  }
  // 获取script的翻译文本
  @computed
  get translationTextsCheckout(): transNewText {
    const { translations } = this.OrderStatusWidgetDataCheckout

    return translations[this.locale || "en"] || {}
  }
  // 获取script的翻译文本
  @computed
  get defaultTranslationTextsScript(): transText {
    const { default_translations } = this.OrderStatusWidgetDataScript

    return default_translations[this.locale || "en"] || {}
  }
  // 获取script的翻译文本
  @computed
  get defaultTranslationTextsCheckout(): transNewText {
    const { default_translations } = this.OrderStatusWidgetDataCheckout

    return default_translations[this.locale || "en"] || {}
  }

  @action.bound
  setOrderStatusWidgetDataScript(data: CheckoutSetting_Script, init?: boolean): void {
    this.OrderStatusWidgetDataScript = data

    settingsApi.setScriptInitOptions({
      translations: this.OrderStatusWidgetDataScript.translations,
      display_type: this.OrderStatusWidgetDataScript.display_type,
    })
    if (init) this.locale = data.primary_locale
  }

  @action.bound
  setOrderStatusWidgetDataCheckout(data: CheckoutSetting_Checkout, init?: boolean): void {
    this.OrderStatusWidgetDataCheckout = data
    settingsApi.setCheckoutInitOptions({
      translations: this.OrderStatusWidgetDataCheckout.translations,
    })
    if (init) this.locale = data.primary_locale
  }

  @action.bound
  setWidgetSetting(path: string, data: any): void {
    // 限制长度为200
    if (typeof data === "string" &&  data.length >  200) {
      data = data.slice(0, 200)
    }
    set(this.isScriptWidget ? this.OrderStatusWidgetDataScript : this.OrderStatusWidgetDataCheckout, path, data)

    stores.commonStore.setContextualSaveBar("show", !isEmpty(this.getDiffOptions()))
  }
  @action.bound
  changeLocale(locale: string): void {
    this.locale = locale
  }
  @action.bound
  setIsScriptWidget(type: boolean): void {
    this.isScriptWidget = type
  }

  @action.bound
  getDiffOptions(): boolean {
    const data = this.isScriptWidget
      ? {
        translations: this.OrderStatusWidgetDataScript.translations,
        display_type: this.OrderStatusWidgetDataScript.display_type,
      }
      : { translations: this.OrderStatusWidgetDataCheckout.translations }

    return deepDiffBetweenObjects(data, this.isScriptWidget ? settingsApi.getScriptInitOptions() : settingsApi.getCheckoutInitOptions())
  }
}
