import { Button, Text, Tooltip } from "@shopify/polaris"
import classNames from "classnames"
import React, { FunctionComponent, ReactNode, SVGProps } from "react"

import styles from "./index.module.scss"

type Info = {
  title: string | ReactNode // 标题
  description?: string // tooltip 描述
  icon?: FunctionComponent<SVGProps<SVGSVGElement>> // icon
  iconClick?: () => void // icon click
  amount: number | string // 数量|值
  render?: (number: number | string) => React.ReactNode
  total?: number,
  totalRender?: (number: number) => React.ReactNode
}
type InfosProps = {
  className?: string
  style?: React.CSSProperties
  infos: Info[]
  disabledTooltip?: boolean
}

const AnalyticInfos = (props: InfosProps) => {
  const { infos, className, style, disabledTooltip } = props

  return <div className={classNames(className, "flex flex-col gap-3")} style={style}>
    {
      infos.map(({ description, title, icon, iconClick, render, amount, totalRender, total }, index) => <div
        key={index}
        className={classNames("flex justify-between")}
      >
        <div className={styles.wrapper}>
          <div className={styles.infoContent}>
            {
              description
                ? <Tooltip content={description} hasUnderline {...(disabledTooltip ? { active: false } : {})}>
                  <Text as={"span"} variant={"bodyMd"}>{title}</Text>
                </Tooltip>
                : <Text as={"span"} variant={"bodyMd"}>{title}</Text>
            }

            {
              icon && <div className={styles.hoverIcon} onClick={iconClick}>
                <Button icon={icon} variant={"plain"} />
              </div>
            }
          </div>
        </div>
        <div className={styles.valuesWrapper}>
          {
            total !== undefined && <div
              className={"lg:mr-[226px]"}
            >{typeof totalRender === "function" && typeof total === "number" ? totalRender(total) : total}</div>
          }
          <div>{typeof render === "function" ? render(amount) : amount}</div>
        </div>
      </div>)
    }
  </div>
}

export default AnalyticInfos
