import { Text } from "@shopify/polaris"
import React from "react"

interface CardTitleProps {
  title: React.ReactNode | string
  description?: React.ReactNode | string
  titleRight?: React.ReactNode | string
}

const CardTitle = ({
  title,
  titleRight,
  description,
}: CardTitleProps) => {
  return <>
    <div className={"flex items-center justify-between relative"}>
      <Text as={"h3"} variant={"headingMd"}>{title}</Text>
      <div className={"absolute right-0 top-0"}>{titleRight}</div>
    </div>
    {
      description && <p className="mt-1">
        <Text as="span" tone="subdued">{description}</Text>
      </p>
    }
  </>
}

export default CardTitle
