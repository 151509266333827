import { ContentState, EditorState } from "draft-js"
import htmlToDraft from "html-to-draftjs"

/**
 * 字符串转EditorState
 * @param value
 */
export const stringToEditorState = (value: string) => {
  const contentBlock = htmlToDraft(value)

  if (contentBlock) {
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks,
    )

    return EditorState.createWithContent(contentState)
  } else {
    return null
  }
}
