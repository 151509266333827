import { Card, Divider } from "@shopify/polaris"
import React from "react"

import { DividerContainer, hasPermission } from "@/component"
import HotBadge from "@/component/HotBadge/HotBadge"
import PermissionBadge from "@/component/PermissionBadge"
import CardTitle from "@/component/TitleGroup/CardTitle"
import Permission from "@/enums/permission"

interface ListCardProps {
  title: string | React.ReactNode
  description: string | React.ReactNode
  isHot?: boolean

  permission?: Permission
  titleRight?: string | React.ReactNode
  children?: string | React.ReactNode
}

const ListCard = ({
  title,
  description,
  isHot,
  permission,
  titleRight,
  children,
}: ListCardProps) => {
  return <Card>
    <CardTitle
      title={
        <div className={"flex items-center gap-2"}>
          <div className={"flex items-center gap-1"}>{title} {isHot && <HotBadge />}</div>
          {permission && !hasPermission(permission) && <PermissionBadge text={"Essential"} permission={permission} />}
        </div>
      }
      description={description}
      titleRight={titleRight}
    />
    <div className={"mx-[-16px] mt-4 mb-1"}>
      <Divider />
    </div>
    {children}
  </Card>
}

export default ListCard
