import "react-responsive-carousel/lib/styles/carousel.min.css"

import { Banner, BannerTone } from "@shopify/polaris"
import { GiftCardIcon } from "@shopify/polaris-icons"
import { ReactElement } from "react"
import { Carousel } from "react-responsive-carousel"

import dashboard from "@/api/dashboard"
import { BannerInterface } from "@/api/dashboard/type"

type BannerCarouselProps = {
  bannerList: BannerInterface[]
  removePromotionalBanner: (id: number) => void
  children?: ReactElement
}
const BannerCarousel = (props: BannerCarouselProps) => {
  const { bannerList, removePromotionalBanner, children } = props

  const Banners = bannerList.length === 0 && !children
    ? null
    : <Carousel
      autoPlay
      infiniteLoop
      showArrows={false}
      interval={5000}
      showStatus={false}
      showThumbs={false}
    >
      <>{
        bannerList.map(item =>
          <Banner
            icon={GiftCardIcon}
            title={item.title}
            tone={item.status}
            action={item.action}
            onDismiss={item.is_closable ? () => {
              removePromotionalBanner(item!.id)
              dashboard.closeBannerById(item!.id)
                .then(null)
            } : undefined}
            key={item.id}
          >
            <p>{item.content}</p>
          </Banner>,
        )
      }
      </>

      {children}
    </Carousel>


  // 如果是独自的，则不使用轮播
  const isAloneBanner = (!children && bannerList.length === 1) || (children && bannerList.length === 0)

  return isAloneBanner
    ? children ? children : <Banner
      icon={GiftCardIcon}
      title={bannerList[0].title}
      status={bannerList[0].status as BannerTone}
      action={bannerList[0].action}
      onDismiss={bannerList[0].is_closable ? () => {
        removePromotionalBanner(bannerList[0]!.id)
        dashboard.closeBannerById(bannerList[0]!.id)
          .then(null)
      } : undefined}
    >
      <p>{bannerList[0].content}</p>
    </Banner>
    : Banners
}

export default BannerCarousel
