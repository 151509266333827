import {
  Button,
  Layout,
  OptionList,
  Page, Popover,
} from "@shopify/polaris"
import { CalendarIcon } from "@shopify/polaris-icons"
import { Observer } from "mobx-react"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Outlet, useLocation, useNavigate } from "react-router-dom"

import { Container } from "@/component"
import Tabs from "@/component/Tabs/index"
import { getDays } from "@/pages/DeliveryGuarantee/common"
import { DateOption } from "@/pages/Orders/components"
import stores from "@/stores"

export default function DeliveryGuarantee() {
  const { t } = useTranslation(["delivery_guarantee", "orders"])
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const [tabIndex, setTabIndex] = useState(0)


  const [popoverActive, setPopoverActive] = useState(false)

  // 时间
  const [dataTimeSelected, setDataTimeSelected] = useState<string>(DateOption.PastQuarter)


  const tabList = [
    {
      content: t("delivery_guarantee:Setting"),
      id: "/protection/setting",
    },
    {
      content: t("delivery_guarantee:Covers"),
      id: "/protection/covers",
    },
    {
      content: t("delivery_guarantee:Claims"),
      id: "/protection/claims",
    },
    {
      content: t("delivery_guarantee:Transactions"),
      id: "/protection/transactions",
    },
  ]

  useEffect(() => {
    const targetPageIndex = tabList.findIndex(tab => tab.id === pathname)

    if (targetPageIndex && targetPageIndex !== tabIndex){
      setTabIndex(targetPageIndex)
    }
  }, [pathname])

  const handleSelectChange = (o: string[]) => {
    setDataTimeSelected(o[0])
    getOverviewData(o[0])
    togglePopoverActive()
  }

  const togglePopoverActive = () => {
    setPopoverActive(!popoverActive)
  }

  const selectOptions = [
    { value: DateOption.PastWeek, label: t("orders:LastNDays", { day: 7 }) },
    { value: DateOption.PastMonth, label: t("orders:LastNDays", { day: 30 }) },
    { value: DateOption.PastQuarter, label: t("orders:LastNDays", { day: 90 })  },
  ]

  const getOverviewData = (dataTime = dataTimeSelected) => {
    if (tabIndex === 0 && stores.DeliveryGuarantee.infoData.isFirstApply === 1){
      // @ts-ignore
      stores.DeliveryGuarantee.getOverviewData(getDays[dataTime])
    }
  }

  useEffect(() => {
    stores.DeliveryGuarantee.getInfo().finally(() => {
      stores.DeliveryGuarantee.setPlusWidgetOns()
    })
  }, [])

  return (
    <Container maxWidth={"1036px"} className={"mt-0"} fullWidth={false}>
      <Page
        // @ts-ignore
        maxWidth={"1096px"}
        title={t("delivery_guarantee:Sett.DeliveryGuarantee")}
        primaryAction={
          <div className={"leading-7"}>
            {t("delivery_guarantee:Sett.PoweredBySeel")}
          </div>
        }
      >
        <Layout>
          <Layout.Section>
            <div className="block justify-between sm:flex">
              <div className="flex-1">
                <Tabs
                  tabs={tabList}
                  activeIndex={tabIndex}
                  onChange={(index:number) => {
                    setTabIndex(index)
                    navigate(tabList[index].id || "/protection")
                    setDataTimeSelected(DateOption.PastQuarter)
                  }}
                />
              </div>
              <Observer>
                {() => <div className={"text-right mt-2 sm:-mt-0.5"}>
                  {tabIndex === 0 && stores.DeliveryGuarantee.infoData.isFirstApply === 1 &&
                    <Popover
                      fluidContent
                      preferredAlignment={"right"}
                      active={popoverActive}
                      activator={
                        <div className={"Polaris-ActionMenu-SecondaryAction"}>
                          <Button icon={CalendarIcon} onClick={togglePopoverActive}>
                            {selectOptions.filter(o => o.value === dataTimeSelected)[0].label}
                          </Button>
                        </div>
                      }
                      onClose={togglePopoverActive}
                      ariaHaspopup={false}
                    >
                      <div className={"max-w-[681px]"}>
                        <OptionList
                          allowMultiple={false}
                          onChange={handleSelectChange}
                          options={selectOptions}
                          selected={[dataTimeSelected]}
                        />
                      </div>
                    </Popover>
                  }
                </div>}
              </Observer>
            </div>
          </Layout.Section>
          <Layout.Section>
            <Outlet />
          </Layout.Section>
        </Layout>
      </Page>

    </Container>
  )
}
