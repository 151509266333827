import { ActionList, Button, Icon, LegacyCard, Popover, ProgressBar, Text } from "@shopify/polaris"
import { CheckIcon, ChevronDownIcon, ChevronUpIcon, MenuHorizontalIcon, XIcon } from "@shopify/polaris-icons"
import classNames from "classnames"
import { useState } from "react"
import { useTranslation } from "react-i18next"

import dashboard from "@/api/dashboard"
import { SetupGuideData } from "@/api/dashboard/type"
import { DashboardSetupGuideEnum } from "@/enums/dashboard"
import LanguageSelect from "@/pages/Dashboard/components/LanguageSelect"
import AddTrackingPage from "@/pages/Dashboard/components/QuickSetupGuide/AddTrackingPage"
import CustomizeShippingNotifications from "@/pages/Dashboard/components/QuickSetupGuide/CustomizeShippingNotifications"
import DropshippingMode from "@/pages/Dashboard/components/QuickSetupGuide/DropshippingMode"
import HowParcelPanelWorks from "@/pages/Dashboard/components/QuickSetupGuide/HowParcelPanelWorks"
import stores from "@/stores"

import styles from "./index.module.scss"

type QuickSetupGuideType = {
  onChangeStep?: () => void
}

const QuickSetupGuide = ({
  onChangeStep,
}: QuickSetupGuideType) => {
  const { t } = useTranslation("dashboard")
  const [quickSetupData, setQuickSetupData] = useState<SetupGuideData["setup_guide"]>(stores.userInfoSore.quickSetupData)

  // 未完成的引导(第一个)
  const uncompletedIndex = quickSetupData.task.findIndex(item => !item.is_completed)

  // 是否展开
  const [expand, setExpand] = useState(uncompletedIndex !== -1)

  // 当前步骤
  const [step, setStep] = useState(uncompletedIndex === -1 ? 0 : uncompletedIndex)

  const [dismissPopoverShow, setDismissPopoverShow] = useState(false)

  const completed = quickSetupData.task.filter(item => item.is_completed)

  const progress = (completed.length / quickSetupData.task.length) * 100

  const stepTaskMap: Record<DashboardSetupGuideEnum, boolean> = {
    [DashboardSetupGuideEnum.ADD_TRACKING_PAGE]: false,
    [DashboardSetupGuideEnum.HOW_PP_WORKS]: false,
    [DashboardSetupGuideEnum.CUSTOMIZE_SHIPPING_NOTIFICATIONS]: false,
    [DashboardSetupGuideEnum.DROPSHIPPING_MODE]: false,
  }

  quickSetupData.task.forEach(item => {
    stepTaskMap[item.id] = item.is_completed
  })

  const toggleCompleted = (index: DashboardSetupGuideEnum, completed: boolean) => {
    return new Promise(((resolve, reject) => {
      dashboard.setSetupGuideStatusById(index, completed)
        .then(res => {
          const quickSetupDataTemp = JSON.parse(JSON.stringify(quickSetupData))

          quickSetupDataTemp.task.find((item: { id: DashboardSetupGuideEnum; is_completed: boolean }) => {
            if (item.id === index) {
              item.is_completed = res.data.is_completed
            }
          })
          setQuickSetupData(quickSetupDataTemp)
          stores.userInfoSore.setQuickSetupData(quickSetupDataTemp)
          resolve(res)
        })
        .then(res => reject(res))
    }))
  }

  const handleTitleClick = (step: number) => {
    setStep(step)
    // 切换时会有一个高度变化动画，鼠标停留位置，导致下方模块的tooltip出现，需要一个事件句柄
    onChangeStep && onChangeStep()
  }

  return !quickSetupData.display ? null
    : <div className={classNames("mb-4", styles.quickSetupGuide, expand && styles.expandQuickSetupGuide)}>
      <LegacyCard
        title={<Text variant="headingMd" as="h2">{t("QuickSetupGuide")}</Text>}
        roundedAbove={"md"}
        actions={[
          {
            // @ts-ignore
            content: <LanguageSelect />,
          },
          {
            // @ts-ignore
            content:
              <Popover
                active={dismissPopoverShow}
                activator={
                  <Button
                    variant={"tertiary"}
                    icon={<Icon source={MenuHorizontalIcon} />}
                    onClick={() => setDismissPopoverShow(!dismissPopoverShow)}
                  />
                }
                onClose={() => setDismissPopoverShow(!dismissPopoverShow)}
              >
                <ActionList
                  actionRole="menuitem"
                  items={[{
                    content: t("dismiss"),
                    /* @ts-ignore*/
                    icon: XIcon,
                    onAction() {
                      dashboard.closeSetupGuide()
                        .then(res => {
                          const temp = JSON.parse(JSON.stringify(quickSetupData))

                          temp.display = false
                          setQuickSetupData(temp)
                        })
                    },
                  }]}
                />
              </Popover>,
          },
          {
            // @ts-ignore
            content:
              <Button
                variant={"tertiary"}
                icon={<Icon source={expand ? ChevronUpIcon : ChevronDownIcon} />}
                onClick={() => setExpand(!expand)}
              />,
          },
        ]}
      >
        <LegacyCard.Section flush>
          {
            progress < 100
              ? <div className={styles.progressBar}>
                <div>{t("TasksComplete", { step: completed.length })}</div>
                <ProgressBar progress={progress} tone={"success"} />
              </div>
              : <div className={styles.isDoneStatus}>
                <Icon source={CheckIcon} tone={"base"} />
                {t("AllTasksComplete")}
              </div>
          }
        </LegacyCard.Section>
        {
          expand && <div className={styles.guideContentWrapper}>
            <AddTrackingPage
              active={step === 0}
              titleClick={() => handleTitleClick(0)}
              toggleCompleted={toggleCompleted}
              step={DashboardSetupGuideEnum.ADD_TRACKING_PAGE}
              isDone={stepTaskMap[DashboardSetupGuideEnum.ADD_TRACKING_PAGE]}
            />
            <CustomizeShippingNotifications
              active={step === 1}
              titleClick={() => handleTitleClick(1)}
              toggleCompleted={toggleCompleted}
              step={DashboardSetupGuideEnum.CUSTOMIZE_SHIPPING_NOTIFICATIONS}
              isDone={stepTaskMap[DashboardSetupGuideEnum.CUSTOMIZE_SHIPPING_NOTIFICATIONS]}
            />
            <DropshippingMode
              active={step === 2}
              titleClick={() => handleTitleClick(2)}
              toggleCompleted={toggleCompleted}
              step={DashboardSetupGuideEnum.DROPSHIPPING_MODE}
              isDone={stepTaskMap[DashboardSetupGuideEnum.DROPSHIPPING_MODE]}
            />
            <HowParcelPanelWorks
              active={step === 3}
              titleClick={() => handleTitleClick(3)}
              toggleCompleted={toggleCompleted}
              step={DashboardSetupGuideEnum.HOW_PP_WORKS}
              isDone={stepTaskMap[DashboardSetupGuideEnum.HOW_PP_WORKS]}
            />
          </div>
        }
      </LegacyCard>
    </div>
}

export default QuickSetupGuide
