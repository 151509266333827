import { Link, Text } from "@shopify/polaris"

import styles from "./index.module.scss"

export interface IProduct {
  image_url: string
  product_id: number
  quantity: number  // 数量
  title: string
  url: string
  variant_id: number
  variant_title: null | string
}

const emptySvg = "https://cdn.parcelpanel.com/front-end/common/imgs/image-not-found.svg"

export default function ProductItem({
  image_url,
  quantity,
  url,
  title,
  variant_title,
}: IProduct) {
  return <div className={"flex gap-2"}>
    <div className={styles.productPicture}>
      <img src={image_url || emptySvg} alt={title} />
    </div>
    <div className={"flex-1 flex flex-col"}>
      <Link removeUnderline url={url} target={"_blank"}>{title}</Link>
      {
        variant_title && <div className={styles.productTag}>
          <Text as={"p"} variant={"bodySm"}>{variant_title}</Text>
        </div>
      }
    </div>
    <div>x{quantity}</div>
  </div>
}
