import { LockIcon } from "@shopify/polaris-icons"
import { useCallback } from "react"

import permissionRules from "@/config/permission-rules"
import Permission from "@/enums/permission"
import { BetaFeature } from "@/models/IUserInfo"
import stores from "@/stores"
import authenticate from "@/utils/authenticate"
import { canIUseBetaFeature } from "@/utils/BetaFeature"

export enum ComponentType {
  BUTTON,
  CHECKBOX,
  SWITCH
}

// 传入当前业务的权限标识，返回是否有权限
export function hasPermission(permission: Permission): boolean {
  return authenticate(permissionRules[permission])
}

/** 功能鉴权器 HOC(高阶组件) */
export function withFeatureAuthenticator(WrappedComponent: (props: any) => JSX.Element, type: ComponentType) {
  return ({ permission, ...props }: { permission: Permission, [k: string]: any }) => {
    // eslint-disable-next-line react/prop-types
    const handler = props.onClick || props.onChange

    const handleProps: { onClick?(args: any): any; onChange?(args: any): any } = {}

    const permissionRule = permissionRules[permission]

    const handleAuth = useCallback(function () {
      if (authenticate(permissionRule) || canIUseBetaFeature(BetaFeature.IGNORE_PERMISSION_CHECK)) {
        // eslint-disable-next-line prefer-rest-params
        handler?.(...arguments)
      } else {
        stores.upgradeStore.showModal(permissionRule)
      }
    }, [handler])

    // eslint-disable-next-line react/prop-types
    if (props.onClick) {
      handleProps.onClick = handleAuth
      // eslint-disable-next-line react/prop-types
    } else if (props.onChange) {
      handleProps.onChange = handleAuth
    }

    // 如果没权限的操作按钮，Icon变成带锁的
    if (!authenticate(permissionRule)) {
      if (type === ComponentType.BUTTON) {
        props.icon = LockIcon
      }

      // if (type === ComponentType.CHECKBOX || type === ComponentType.SWITCH){
      //   props.disabled = true
      // }

      // 所有均禁用
      props.disabled = true

      if (canIUseBetaFeature(BetaFeature.IGNORE_PERMISSION_CHECK)) {
        props.disabled = false
      }
    }


    return <WrappedComponent {...props} {...handleProps} />
  }
}
