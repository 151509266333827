import { BaseResponse } from "@/api/base"
import { ITrackingResultStateData } from "@/api/TP2/tracking-info.d"
import { languageType } from "@/pages/Settings/EmailEdit/type"
import request from "@/utils/request"
import { CancelTypesEnum } from "@/utils/request.d"

export default {
  /**
   * 获取 Tracking info 信息
   */
  getTrackingInfo(id: string | number, primaryLocale: languageType | string, executiveTranslation: string = "") {
    interface RES extends BaseResponse {
      data: ITrackingResultStateData
    }

    return request.get<RES>(`${import.meta.env.VITE_PP_PROXY_API_URL}tracking-info`, {
      data: {
        cancelType: CancelTypesEnum.PATH,
      },
      params: { id, lang: primaryLocale, executive_translation: executiveTranslation },
    })
  },
}
