import { Text } from "@shopify/polaris"
import classNames from "classnames"
import { useCallback } from "react"

import { PageTabProps } from "./index.d"
import styles from "./index.module.scss"


const Tabs = ({
  activeIndex,
  tabs,
  onChange,
}: PageTabProps) => {
  const handleTabChange = useCallback((index:number) => {
    activeIndex !== index && typeof onChange === "function" && onChange(index)
  }, [activeIndex, onChange])

  return <div className={styles.tabsWrapper}>
    <ul className={styles.tabs}>
      {
        tabs.map((tab, index) => <li key={tab.id} className={styles.tabs__TabContainer}>
          <div
            className={classNames(styles.tab__Button, index === activeIndex ? styles.tab__ButtonActive : "")}
            onClick={() => handleTabChange(index)}
          >
            <Text variant={"bodyMd"} fontWeight={"medium"} as={"span"}>{tab.content}</Text>
            {!!tab.suffix && tab.suffix}
          </div>
        </li>)
      }
    </ul>
  </div>
}

export default Tabs
