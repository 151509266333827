import type { IProduct } from "./productItem"
import ProductItem from "./productItem"

interface ShipmentsProductsProps {
  products: IProduct[]
}

export default function ShipmentsProducts ({
  products,
}:ShipmentsProductsProps) {
  return <div className={"flex flex-col gap-4"}>
    {products.map((product: IProduct, index) =>  <ProductItem {...product} key={index} />)}
  </div>
}
