import {
  ContextualSaveBar as ContextualSaveBarActions,
} from "@shopify/app-bridge/actions"
import { isShopifyEmbedded } from "@shopify/app-bridge-utils"
import {
  Banner,
  ContextualSaveBar,
  Page,
} from "@shopify/polaris"
import { cloneDeep, isEmpty, isNumber, trimStart } from "lodash-es"
import { Observer } from "mobx-react"
import { deepObserve } from "mobx-utils"
import { IDisposer } from "mobx-utils/lib/utils"
import qs from "qs"
import React, { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { useIntercom } from "react-use-intercom"

import { APICode } from "@/api/APICode"
import orders from "@/api/orders"
import { Container } from "@/component"
import UnsavedPrompt from "@/component/UnsavedPrompt"
import { QueryParamsType } from "@/pages/Orders/types"
import stores from "@/stores"
import AppBridge from "@/utils/appBridge"
import { deepDiffBetweenObjects } from "@/utils/ObjectUtils"
import toast from "@/utils/toast"

import {
  IQueryParams,
  ISearchParams,
  IUpdateStatusState,
  StatusList,
  UpdateStatusData,
  UpdateStatusSelectFrom,
} from "./types"
import UpdateStatusList from "./UpdateStatusList"

type ParamsStateType = Pick<ISearchParams, "from"> & IQueryParams;

// 从 API 获取的初始数据
export let UpdateStatusListData: UpdateStatusData
export let disposer: IDisposer

// 注册监听器
const registerListener = () => {
  const { data } = stores.ordersStore.updateOrderStatus

  if (!data?.status_list) return false

  // console.log('UpdateStatus：注册监听器')
  disposer = deepObserve(data, (change, path) => {
    if (change.type === "update") {
      stores.commonStore.setContextualSaveBar("show", !isEmpty(getDiffOptions()))
    }
  })

  return true
}

// 销毁监听器
const destroyListener = () => {
  disposer && disposer()
  stores.commonStore.setContextualSaveBar("show", false)
  stores.commonStore.setContextualSaveBar("loading", false)
  return true
}

async function requestSave({ order_ids, number_ids }: IQueryParams) {
  stores.commonStore.setContextualSaveBar("loading", true)
  const { data } = stores.ordersStore.updateOrderStatus
  const status_list: StatusList[] = cloneDeep(data!.status_list),
    { status_checked } = data!

  if (data?.custom_track_status === 0) {
    // MARK: chuwen 2022/7/19 09:30 如果是自动更新的状态
    // status_list = filter(status_list, (updateStatusItem, index) => index === 0 || updateStatusItem.value === true)
    // checked_index = status_list.length - 1
  }

  const { data: { code, msg, data: new_data } } = await orders.setUpdateStatusList({
    auto: data!.auto,
    custom_track_status: status_list[status_checked].status,
    number_ids,
    order_ids,
    status_checked,
    status_list,
  })

  // 请求完成
  stores.commonStore.setContextualSaveBar("loading", false)

  if (APICode.OK !== code) {
    // TODO: chuwen 2022/7/18 18:57 异常处理
    return
  }

  destroyListener()// 销毁监听器
  data && (UpdateStatusListData = new_data)// 重新赋值
  stores.ordersStore.setUpdateStatusData(new_data)// 从接口中获取最新的值并赋值
  registerListener()// 注册监听器

  toast(msg)
}

export default function UpdateStatus() {
  const { t } = useTranslation("orders")
  const CommonIl8n = useTranslation("common").t
  const { search, state } = useLocation() as {
    search: string;
    state: IUpdateStatusState
  }
  const navigateFunction = useNavigate()

  const goBackHandle = () => {
    navigateFunction("/orders")
  }

  const [searchParamsData] = useSearchParams()

  const onAction = () => {
    const ordersQuery = searchParamsData.get("query")

    if (ordersQuery) {
      navigateFunction("/orders/detail" + decodeURIComponent(ordersQuery))
    } else {
      goBackHandle()
    }
  }
  const handleDiscardAction = () => {
    destroyListener()
    const { setUpdateStatusData } = stores.ordersStore

    setUpdateStatusData(UpdateStatusListData)
    registerListener()
  }
  // ####################################//
  // 变量定义
  // ####################################//
  const searchParams = useMemo(() => {
    return qs.parse(trimStart(search, "?")) as {
      from: ISearchParams["from"],
      number_ids?: IQueryParams["number_ids"], // 从我们的 Orders 进来可能会有这个参数
      ids?: IQueryParams["order_ids"], // 从 Shopify Orders 进来会有这个参数<选择多个订单>
      id?: string, // 从 Shopify Orders 进来会有这个参数<选择单个订单>
    }
  }, [search])

  const [count, setCount] = useState<number>(0)
  const [paramsState, setParamsState] = useState<ParamsStateType>()
  // ####################################//
  // ####################################//

  useEffect(initData, [])

  useEffect(() => {
    if (!paramsState) return

    let count = 0

    if (paramsState?.order_ids) {
      count = paramsState?.order_ids?.length ?? 0
    } else if (paramsState?.id) {
      count = 1
    } else {
      count = paramsState?.number_ids?.length ?? 0
    }

    // 如果不是数字，给他赋值为0
    !isNumber(count) && (count = 0)
    count === 0 && goBackHandle()

    setCount(count)
    initStatusList()
  }, [paramsState?.order_ids, paramsState?.number_ids])

  const i18nKey = searchParams.from === UpdateStatusSelectFrom.ShopifySelect
    ? count === 1 ? "UpdateStatus.title_order" : "UpdateStatus.title_order_other"
    : count === 1 ? "UpdateStatus.title_shipment" : "UpdateStatus.title_shipment_other"

  return (<div id="UpdateStatusWrap">
    <Observer>{() => {
      // 全局保存
      const saveBar = stores.commonStore.contextualSaveBar

      if (isShopifyEmbedded() && !window.isFromAdmin) {
        const ShopifyContextualSaveBar = ContextualSaveBarActions.create(AppBridge.app, {
          saveAction: {
            label: CommonIl8n("Save"),
            disabled: false,
            loading: false,
          },
          discardAction: {
            label: CommonIl8n("Discard"),
            disabled: false,
            loading: false,
            discardConfirmationModal: false,
          },
          leaveConfirmationDisable: true,
        })

        ShopifyContextualSaveBar.subscribe(ContextualSaveBarActions.Action.DISCARD, () => {
          handleDiscardAction()
          ShopifyContextualSaveBar.dispatch(ContextualSaveBarActions.Action.HIDE)
        })
        ShopifyContextualSaveBar.subscribe(ContextualSaveBarActions.Action.SAVE, () => {
          // this.handleCheckoutTextSaveClick()
          requestSave({ order_ids: paramsState?.order_ids, number_ids: paramsState?.number_ids })
        })
        ShopifyContextualSaveBar
          .set({
            saveAction: { loading: saveBar.loading },
          })
          .dispatch(saveBar.show || saveBar.loading ? ContextualSaveBarActions.Action.SHOW : ContextualSaveBarActions.Action.HIDE)
      }
      return (<>
        <UnsavedPrompt
          title={CommonIl8n("LeavePageTitle")}
          message={CommonIl8n("LeavePageMessage")}
          secondaryLabel={CommonIl8n("Stay")}
          when={stores.commonStore.contextualSaveBar.show}
          confirm={handleDiscardAction}
          isShopifyEmbedded={isShopifyEmbedded() && !window.isFromAdmin}
        />

        {/* 顶部保存条 */}
        {(!isShopifyEmbedded() || window.isFromAdmin) && stores.commonStore.contextualSaveBar.show &&
          <ContextSaveBar order_ids={paramsState?.order_ids} number_ids={paramsState?.number_ids} />}

        <Container id="Page-UpdateStatus" className="pt-0 pb-5" maxWidth="796px">
          <Page
            backAction={{ onAction: onAction }}
            title={t("ManuallyUpdateStatus")}
            subtitle={t(i18nKey, { "count": count })}
            compactTitle
          >
          </Page>

          {stores.ordersStore.updateOrderStatus.data?.order_has === 1 && <EmptyOrderBanner />}
          {stores.ordersStore.updateOrderStatus.data?.order_has !== 1 &&
            <UpdateStatusList count={count} from={searchParams.from} />}
        </Container>
      </>)
    }}</Observer>
  </div>)

  // //////////////////////////////
  // /////////代码分割线////////////
  // //////////////////////////////
  function initData() {
    // 如果没有 from 参数，就返回去
    if (!(searchParams.from in UpdateStatusSelectFrom) || (searchParams.from === UpdateStatusSelectFrom.SelectAll && !state)) {
      goBackHandle()
      return
    }

    initParamsState()

    // /////////////////////////////////////////
    function initParamsState() {
      const $paramsState: ParamsStateType = {
        from: searchParams.from,
      }

      switch (searchParams.from) {
        case UpdateStatusSelectFrom.SelectAll:// 选择全部
          // MARK: chuwen 2022/7/13 09:29 如果是空的，返回上一页
          if (!state.queryParams) {
            goBackHandle()
            return
          }

          // eslint-disable-next-line no-case-declarations
          const asyncResponse = async () => {
            const {
              data: {
                code, data: { number_ids },
              },
            } = await orders.queryFieldByParam(state.queryParams as QueryParamsType)

            if (APICode.OK === code) {
              $paramsState.number_ids = number_ids
              setParamsState($paramsState)
            }
          }

          asyncResponse()
          return

        case UpdateStatusSelectFrom.MultiSelect:// 多选
          $paramsState.number_ids = searchParams.number_ids
          break

        case UpdateStatusSelectFrom.ShopifySelect:// 从 Shopify 选择
          if (searchParams.id && !isEmpty(searchParams.id)) {
            $paramsState.order_ids = [searchParams.id]
          } else {
            $paramsState.order_ids = searchParams.ids
          }
          break
      }

      // 设置参数
      setParamsState($paramsState)
    }

    return () => {
      // 组件销毁执行的事件
      destroyListener()
      stores.ordersStore.setUpdateStatusLoading(true)
      stores.ordersStore.setUpdateStatusData(null as any)
    }
  }

  /**
   * 初始化状态列表
   */
  function initStatusList() {
    const { setUpdateStatusLoading, setUpdateStatusData } = stores.ordersStore

    const request = async () => {
      const { data: { code, data } } = await orders.getUpdateStatusList({
        number_ids: paramsState?.number_ids,
        order_ids: paramsState?.order_ids,
      })

      if (APICode.OK === code) {
        setUpdateStatusLoading(false)
        setUpdateStatusData(data)
        UpdateStatusListData = data // 初始值赋值
        destroyListener()// 先销毁监听器
        registerListener()// 注册变动监听器
      }
    }

    request()
  }
}

function EmptyOrderBanner() {
  const { t } = useTranslation("common")
  const { showNewMessages } = useIntercom()

  return (
    <div className="mb-4">
      <Banner
        title={t("SomethingWrongOrder")}
        action={{ content: t("ContactSupport"), onAction: () => showNewMessages("") }}
        tone="warning"
      >
        <p>{t("OrderNotSynced")}</p>
      </Banner>
    </div>
  )
}

function ContextSaveBar({ order_ids, number_ids }: IQueryParams) {
  const { t } = useTranslation("common")

  return (
    <Observer>{() => {
      return <><ContextualSaveBar
        fullWidth
        alignContentFlush
        message={t("UnsavedChanges")}
        saveAction={{
          loading: stores.commonStore.contextualSaveBar.loading,
          onAction: () => {
            stores.commonStore.setContextualSaveBar("loading", true)
            requestSave({ order_ids, number_ids })
          },
        }}
        discardAction={{
          onAction: () => {
            destroyListener()
            const { setUpdateStatusData } = stores.ordersStore

            setUpdateStatusData(UpdateStatusListData)
            registerListener()
          },
        }}
      /></>
    }}</Observer>
  )
}

/**
 * 获得差异配置信息
 * @return 如果有变更，会返回数组对象。否则返回空数组 Array.length === 0
 */
function getDiffOptions(): Array<Partial<StatusList>> {
  const { data } = stores.ordersStore.updateOrderStatus

  if (!UpdateStatusListData || !data) return []

  const object = cloneDeep(data)
  let currentStatusChecked = object.status_checked
  const originalStatusChecked = UpdateStatusListData.status_checked

  if (!object.status_list[currentStatusChecked].value) {
    currentStatusChecked -= 1
  }

  if (object.auto && object.auto === UpdateStatusListData.auto) {
    return []
  }

  if (
    object.auto === UpdateStatusListData.auto
    && currentStatusChecked === originalStatusChecked
    && object.status_list[currentStatusChecked]?.date === UpdateStatusListData.status_list[originalStatusChecked]?.date
  ) {
    return []
  }

  return deepDiffBetweenObjects(object, UpdateStatusListData)
}
