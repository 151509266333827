import baseRequest from "@/utils/baseRequest"

import {
  AppBlockStatus,
  DefaultRuleDataType,
  DefaultRuleI,
  ExclusionRuleDataType,
  RulesDataType, SpecificRuleDataType,
  WidgetData,
} from "./type/preEdd"

export default {
  /**
   * 获取 EDD Widget配置
   */
  getWidgetConfig() {
    return baseRequest.get<WidgetData>("v2/pre_edd/widget")
  },
  /**
   * 获取 APP Block状态
   */
  getAppBlockStatus() {
    return baseRequest.get<AppBlockStatus>("v3/settings/app_block_active_state")
  },
  /**
   * 获取 EDD Widget配置
   */
  setWidgetConfig(data: { widget: Partial<WidgetData["widget"]>}) {
    return baseRequest.put<WidgetData>("v2/pre_edd/widget", data)
  },
  /**
   * 获取 EDD default rule
   */
  getDefaultRule() {
    return baseRequest.get<DefaultRuleDataType>("v2/pre_edd/default_rule")
  },
  /**
   * 获取 EDD specific rule
   */
  getSpecificRule() {
    return baseRequest.get<SpecificRuleDataType>("v2/pre_edd/specific_rules")
  },
  /**
   * 获取 EDD exclusion rule
   */
  getExclusionRule() {
    return baseRequest.get<ExclusionRuleDataType>("v2/pre_edd/exclusion_rules")
  },
  /**
   * 设置 EDD rules
   */
  setRule(data: { rule: RulesDataType }) {
    return baseRequest.put<WidgetData>("v2/pre_edd/rule", data)
  },
}
