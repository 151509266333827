import { action, computed, makeObservable, observable } from "mobx"

import { IContextualSaveBar } from "@/models/ICommonStore"
import Api from "@/pages/DeliveryGuarantee/Api"
import { CartSettingApi, CheckWidgetApi, InfoApi, OverviewApi, SysConfigA } from "@/pages/DeliveryGuarantee/Api/type"
import GuaranteeWidgetUtils from "@/pages/DeliveryGuarantee/DGSetting/components/GuaranteeWidget/GuaranteeWidgetUtils"


export default class deliveryGuarantee {
  // 请求审核的弹窗
  @observable guaranteeModule = false

  // 高亮弹窗配置
  @observable highlightShow = false


  @observable sysConfig: SysConfigA = {
    plusBlock: 0,
    remindOne: 0,
    remindTwo: 0,
    remindThr: 0,
    remindFour: 0,
    remindFive: 0,
  }

  @observable overviewData: OverviewApi = {
    orders: "",
    revenue: 0,
    sales: 0,
    symbol: "",
    isOrder: false,
  }

  @observable infoData:InfoApi = {
    user_id: "",
    payNumber: "",
    audit: 0,
    auditRet: "",
    currency: "CNY",
    isPaid: false,
    isViewAudit: 0,
    paymentMethod: 0,
    storeName: "",
    isFirstApply: 0,
    planDisplayName: "",
    isPlus: false,
    isAuth: true,
    authUrl: "",
  }

  @observable cartSetting:CartSettingApi = {
    defaultDisplayStatus: 2,
    fulfillmentType: 2,
    isEnable: false,
    isFit: 2,
  }

  @observable public widgetSaveBar: IContextualSaveBar = {
    disabled: false,
    loading: false,
    show: false,
    unlocking: false,
  }

  @observable public detectionCheck: CheckWidgetApi = {
    appEmbedLink: "",
    // 是否开启
    enableWidget: false,
    scopeUrl: false,
    webExtension: {
      insuranceCheckout: false,
      insurancePopup: true,
    },
  }



  // @observable trackingLinHistoryData:TrackingLinkData | undefined;


  constructor() {
    makeObservable(this)
  }



  @action.bound
  setData(key: string, data: any) {
    // @ts-ignore
    this[key] = data
  }

  @action.bound
  setCartSetting(key: keyof CartSettingApi, btnText: any): void {
    this.cartSetting[key] = btnText as never
  }

  @action.bound
  setWidgetSaveBar(key: keyof IContextualSaveBar, btnText: any): void {
    this.widgetSaveBar[key] = btnText as never
  }
  
  @action.bound
  setSysConfig(value:number | SysConfigA, type?: keyof SysConfigA):void{
    // 设置banner配置等一些开关配置
    if (type && typeof value === "number"){
      this.sysConfig[type] = value
    } else {
      this.sysConfig = <SysConfigA>value
    }
  }

  @action.bound
  getCartSetting(calls?:()=>void):void{
    // 获取小部件设置
    Api.getCartSetting()
      .then(o => {
        if (o.data.data) {
          this.setData("cartSetting", o.data.data)
          GuaranteeWidgetUtils.destroyListener()
          GuaranteeWidgetUtils.setInitOptions(o.data.data)
          GuaranteeWidgetUtils.registerListener()
        }
      })
      .finally(() => calls && calls())
  }

  @action.bound
  // 获取用户数据
  async getInfo(){
    await Api.getInfo()
      .then(o => {
        this.setData("infoData", o.data.data)
      })
    return true
  }

  @action.bound
  // 获取用户数据
  async getOverviewData(dataTime:string){
    await Api.getOverviewData(dataTime)
      .then(o => {
        this.setData("overviewData", o.data.data)
      })

  }

  @action.bound
  // 获取用户数据
  setPlusWidgetOns(){
    if (this.infoData.isAuth && this.infoData.isPlus){
      // 调用获取权限接口isAuth
      // 获取权限接口为isEnable为true的话 就需要重新调用 一下 小部件配置
      Api.setPlusWidgetOn().then(o => {
        if (o.data.data.isEnable){
          this.getCartSetting()
        }
      })
    }
  }

  @computed get getInsurancePopup(): boolean{
    // 小部件程序块是否打开 ，排除了plus     false 是关闭，则需要展示弹窗

    return this.infoData.isPlus ? true : this.detectionCheck.webExtension.insurancePopup
  }


}
