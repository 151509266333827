import { Button, Icon, Modal, Text } from "@shopify/polaris"
import { XIcon } from "@shopify/polaris-icons"
import React from "react"
import { Trans, useTranslation } from "react-i18next"
import { useIntercom } from "react-use-intercom"

import { CurrentSubscription, Plan, PlanPricing } from "@/api/account/type.d"

import styles from "./index.module.scss"

type DowngradeModalProps = {
  show: boolean
  currentSubscription: CurrentSubscription
  tempPlanInfo: { level: number, planName: string, activePrice: PlanPricing, isLowYearToHighMonth?: boolean, isHighMonthToLowerYear?: boolean } | null
  currentPlan?: Plan
  onClose: () => void
  confirm: () => void
}

/**
 * 降级提醒弹窗
 * @constructor
 */
const DowngradeModal = (props: DowngradeModalProps) => {
  const { t } = useTranslation()
  const { show, onClose, confirm, currentSubscription, tempPlanInfo, currentPlan } = props
  const { showNewMessages } = useIntercom()

  if (tempPlanInfo && tempPlanInfo.isHighMonthToLowerYear) {
    return <Modal
      instant
      open={show}
      onClose={onClose}
      title={t("account:ChangePlanReminder")}
      primaryAction={{
        content: t("account:ChangePlan"),
        destructive: true,
        onAction: confirm,
      }}
      secondaryActions={[
        {
          content: t("common:Cancel"),
          onAction: onClose,
        },
      ]}
      sectioned
    >
      <p>{t("account:DowngradeReminderDesc3", {
        current_plan_name: currentPlan?.name,
        next_plan_name: tempPlanInfo.planName,
      })}</p>
      {
        // 降级弹窗，如果降了套餐等级，显示这段提示, (年费套餐新增：升套餐版本的降级（低版本年费 → 高版本月费）)
        tempPlanInfo && (tempPlanInfo.level < currentSubscription.level || tempPlanInfo.isLowYearToHighMonth)
        && <p className={"mt-3"}>
          <span className={"mr-1"}>
            <Text
              as="span"
              fontWeight="semibold"
            >
              {t("account:Important")}
            </Text>
          </span>
          {t("account:DowngradeReminderImportant", { planName: currentSubscription.plan_name })}
        </p>
      }

      {
        // 降级弹窗，如果降了套餐等级，显示这段提示
        tempPlanInfo && tempPlanInfo.level < currentSubscription.level &&
        <div className={"mt-3 gap-x-4 gap-y-2 flex flex-wrap"}>
          {
            !!currentPlan && [...currentPlan.features_overview, t("account:ManyMore")].map((feature, index) => <div
              key={index} className={styles.featureItem}
            >
              <div className={styles.featureItemIcon}>
                <Icon source={XIcon} />
              </div>
              <p>{feature}</p>
            </div>)
          }
        </div>
      }
      <div className={"mt-3 font-semibold"}>
        <Text
          as="span"
          fontWeight="semibold"
        >
          {t("account:UpgradingSuggestion")}
        </Text>
      </div>
    </Modal>

  }

  return (
    <Modal
      instant
      open={show}
      onClose={onClose}
      title={t("account:DowngradeReminder")}
      primaryAction={{
        content: t("account:DegradeAlert.primaryButtonText"),
        destructive: true,
        onAction: confirm,
      }}
      secondaryActions={[
        {
          content: t("common:Cancel"),
          onAction: onClose,
        },
      ]}
      sectioned
    >
      <p>
        <Trans ns="account" i18nKey="DowngradeReminderDesc"
          values={{ date: currentSubscription.billing_cycle_end_date }}
        >
          {/* @ts-ignore */}
          <Text as="span" fontWeight="semibold" />
        </Trans>
      </p>
      {
        // 降级弹窗，如果降了套餐等级，显示这段提示, (年费套餐新增：升套餐版本的降级（低版本年费 → 高版本月费）)
        tempPlanInfo && (tempPlanInfo.level < currentSubscription.level || tempPlanInfo.isLowYearToHighMonth)
        && <p className={"mt-3"}>
          <span className={"mr-1"}>
            <Text
              as="span"
              fontWeight="semibold"
            >
              {t("account:Important")}
            </Text>
          </span>
          {
            tempPlanInfo.isLowYearToHighMonth
              ? t("account:LowYearToHighMonth")
              : t("account:DowngradeReminderImportant", { planName: currentSubscription.plan_name })
          }

          {
            tempPlanInfo.isLowYearToHighMonth && <p className={"mt-3"}>
              <Trans
                ns="account"
                i18nKey="InThisCase"
              >
                <Button

                  onClick={() => {
                    onClose()
                    showNewMessages()
                  }}
                  variant="plain"
                />
              </Trans>
            </p>
          }

        </p>
      }

      {
        // 降级弹窗，如果降了套餐等级，显示这段提示
        tempPlanInfo && tempPlanInfo.level < currentSubscription.level &&

        <div className={"mt-3 gap-x-4 gap-y-2 flex flex-wrap"}>
          {
            !!currentPlan && [...currentPlan.features_overview, t("account:ManyMore")].map((feature, index) => <div
              key={index} className={styles.featureItem}
            >
              <div className={styles.featureItemIcon}>
                <Icon source={XIcon} />
              </div>
              <p>{feature}</p>
            </div>)
          }
        </div>
      }
    </Modal>
  )
}

export default DowngradeModal
