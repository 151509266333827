import { Text } from "@shopify/polaris"
import React from "react"

import { SwitchChangeEventHandler } from "@/component/Switch/Switch"

import { Switch } from "../"

interface TitleSwitchProps {
  title: string;
  checked: boolean;
  disabled?: boolean;
  titleStyle?: React.CSSProperties
  onChange?: SwitchChangeEventHandler;
}

export default function TitleSwitch({ checked, onChange, title, disabled, titleStyle }: TitleSwitchProps) {
  return (
    <div className="flex justify-between h-5 items-center">
      <div style={{ ...titleStyle }}>
        <Text as={"h3"} variant={"bodyMd"} fontWeight={"medium"}>{title}</Text>
      </div>
      <Switch checked={checked} onChange={onChange} disabled={disabled} />
    </div>
  )
}
