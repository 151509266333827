export interface ITrackingPageOptions {
  displayOption: DisplayOption | undefined;
  pageTranslations: PageTranslations | undefined;
  autoTranslate: AutoTranslate | undefined;
  pageTranslationsD: PageTranslations | undefined;
  customOrderStatus: CustomOrderStatus | undefined;
  customOrderInput: CustomOrderInput | undefined;
  post_purchase_edd: PostPurchaseEDD | undefined;
  proRecommendAndUpsell: ProRecommendAndUpsell | undefined;
  additionalTextSetting: AdditionalTextSetting | undefined;
  seoOptimization: SEOOptimization | undefined;
  dateAndTimeFormat: DateAndTimeFormat | undefined;
  translateTrackingDetailedInfo: TranslateTrackingDetailedInfo[] | undefined;
  customCssAndHtml: CustomCSSAndHTML | undefined;
  brandingConfig: BrandingConfig | undefined;
  productCollection: any;
  trackingPageUrl: TrackingPageUrl | undefined

  translations: Translations | undefined
  translations_config: TranslationsConfig | undefined
}

export interface AdditionalTextSetting {
  text_above: string;
  text_below: string;
}

export interface AutoTranslate {
  enable: boolean;
  targetLang: string[];
  langList: LangList[];
  hot: boolean;
  hotText: string;
  returnDefaultLang: boolean;
}

export interface LangList {
  value: string;
  label: string;
}

export interface CustomCSSAndHTML {
  cssCode: string;
  htmlTopCode: string;
  htmlBottomCode: string;
}


export interface TrackingPageUrl {
  subpathPrefix: string
  subpath: string
}

export interface CustomOrderInput {
  days: string;
  trackingInfo: string;
}

export interface CustomOrderStatus {
  ordered: Delivered;
  order_ready: Delivered;
  in_transit: Delivered;
  out_of_delivery: Delivered;
  delivered: Delivered;
}

export interface Delivered {
  icon: string;
  name: string;
  children: Child[];
}

export interface Child {
  id: number;
  status: string;
  info: string;
  days: string;
}

export interface PageTranslations {
  track_your_order: string;
  order_number: string;
  expected_delivery: string;
  or: string;
  email: string;
  track: string;
  order: string;
  tracking_number: string;
  product: string;
  carrier: string;
  status: string;
  order_not_found: string;
  enter_your_order: string;
  enter_your_email: string;
  enter_your_tracking_number: string;
  not_yet_shipped: string;
  ordered: string;
  order_ready: string;
  waiting_update: string;
  shipping_to: string;
  current_location: string;
  may_like: string;
  pending: string;
  transit: string;
  pickup: string;
  delivered: string;
  expired: string;
  undelivered: string;
  exception: string;
  info_received: string;
}

export interface DateAndTimeFormat {
  date_format: string;
  time_format: string;
}

export enum ThemeContainerWidthType {
  PERCENTAGE = 1, // 百分比
  PIXEL = 2, // 像素
}

export enum QuotaReminderType {
  PERCENTAGE = "1", // 百分比
  AMOUNT = "2", // 数量
}

export enum UIStyleEnum {
  LIGHT = 0,
  DARK = 1,
}

export enum ProgressBarLayoutEnum {
  HORIZONTAL = 0,
  VERTICAL = 1,
}

export interface DisplayOption {
  themeContainerWidth: number | string;
  themeContainerWidthType: ThemeContainerWidthType;
  progressBarColor: string;
  progressBarLayout: ProgressBarLayoutEnum;
  uiStyle: UIStyleEnum;
  lookUpOption: LookUpOption;
  carrierDetails: boolean;
  trackingNumber: boolean;
  packageContentsDetails: boolean;
  trackingDetailedInfo: boolean;
  googleTranslateWidget: boolean;
  mapCoordinates: MapCoordinates;
  hideKeywords: string;
}

export interface LookUpOption {
  BONE: boolean;
  BTN: boolean;
}

export interface MapCoordinates {
  showMap: boolean;
  position: number;
}

export type SelectCountries = {
  "code": string,
  "provinces": { "code": string, "checked"?: boolean }[]
}[]

export type TimeRulesType = {
  "date_range": {
    "min": number,
    "max": number
  },
  "countries": SelectCountries
}

type FulfillmentWorkflow = {
  "cutoff_time": {
    "time": string,
    "tz": string
  },
  "business_days": number[],
  "processing_time": number | ""
}

type TransitTime = {
  "rules": TimeRulesType[],
  "default": {
    "date_range": {
      "min": number,
      "max": number
    }
  }
}

type NonBusinessDayPeriod = {
  start_date: string
  end_date: string
} | undefined

export interface PostPurchaseEDD {
  "enabled": boolean,
  "calc_from": 1 | 2,
  "fulfillment_workflow": FulfillmentWorkflow,
  "transit_time": TransitTime
  "non_business_day_periods": NonBusinessDayPeriod[]
}

export type PostPurchaseEDDModule = Pick<PostPurchaseEDD, "fulfillment_workflow" | "transit_time">

export interface ProRecommendAndUpsell {
  productRecommendations: ProductRecommendations;
  discountData: DiscountData;
}

export interface DiscountData {
  is_show: boolean;
  heading: string;
  description: string;
  discount_code: string;
  button_text: string;
  button_link: string;
  check_time: number;
}

export enum ProductRecommendIntent {
  ShopifyRecommendations= 0,
  SpecificCollection= 1,
  NewestCreated= 2,
  BestSellers = 3,
  FrequentlyBoughtTogether = 4,
}

export interface ProductRecommendations {
  show: boolean;
  position: number;
  adv_coll_enabled: boolean;
  collect: number;
  intent: ProductRecommendIntent;
}

export interface SEOOptimization {
  valueSeoTitle: string;
  valueSeoCon: string;
}

export interface TranslateTrackingDetailedInfo {
  before: string;
  after: string;
  sort?: number;
}

export interface BrandingConfig {
  isDisabled: boolean;
  isRemovedBranding: boolean;
}

export interface Translations {
  [lang: string]: Langs | any
}

export interface Langs {
  track_your_order: string
  order_number: string
  expected_delivery: string
  or: string
  email: string
  track: string
  order: string
  tracking_number: string
  product: string
  carrier: string
  status: string
  order_not_found: string
  enter_your_order: string
  enter_your_email: string
  enter_your_tracking_number: string
  not_yet_shipped: string
  ordered: string
  order_ready: string
  waiting_update: string
  shipping_to: string
  current_location: string
  may_like: string
  pending: string
  transit: string
  pickup: string
  delivered: string
  expired: string
  undelivered: string
  exception: string
  info_received: string
  recommended_title: string
  features_text: FeaturesText
}

export interface FeaturesText {
  custom_status: Status[]
  additional_text_above?: string
  additional_text_below?: string
  custom_tracking_info?: string
}

export interface Status {
  status?: string
  info?: string
}

export interface TranslationsConfig {
  auto_switch: boolean
  primary_locale: string
  locales: Locale[]
}

export interface Locale {
  name: string
  value: string
}
