import { Button, ButtonGroup, Modal, TextField, Tooltip, VideoThumbnail } from "@shopify/polaris"
import { ClipboardIcon, ViewIcon } from "@shopify/polaris-icons"
import classNames from "classnames"
import copy from "copy-to-clipboard"
import React, { useState } from "react"
import { Trans, useTranslation } from "react-i18next"

import { DashboardSetupGuideEnum } from "@/enums/dashboard"
import StepWrapper from "@/pages/Dashboard/components/QuickSetupGuide/StepWrapper"
import stores from "@/stores"
import { appProxySubpathPrefixList } from "@/utils/constant"
import { openLink } from "@/utils/redirectShopify"
import toast from "@/utils/toast"

import styles from "./index.module.scss"

function getShortTrackLink(url: string) {
  // 确保 URL 是完整的
  const fullUrl = url.startsWith("http") ? url : `https://${url}`

  const urlParts = new URL(fullUrl)
  const { pathname } = urlParts

  for (const prefix of appProxySubpathPrefixList) {
    if (pathname.startsWith(`/${prefix}`)) {
      const splitPath = pathname.split("/").slice(2) // 去掉前缀部分

      return `/${prefix}/${splitPath}`
    }
  }

  return pathname // 如果没有匹配的前缀，返回原路径
}

type IProps = {
  active: boolean
  isDone?: boolean
  toggleCompleted: (status: DashboardSetupGuideEnum, completed: boolean) => Promise<any>
  step: DashboardSetupGuideEnum
  titleClick?: () => void
}

const AddTrackingPage = (props: IProps) => {
  const { t } = useTranslation(["dashboard", "common"])
  const [videoModalShow, setVideoModalShow] = useState(false)
  const { userInfoSore } = stores
  const { toggleCompleted, step, isDone } = props

  // video信息
  const videoTime = 69
  const videoUrl = "https://cdn.parcelpanel.com/front-end/2023/guide/HowToAddTheTrackingPage.mp4"
  const posterUrl = "https://cdn.parcelpanel.com/front-end/2023/guide/cover.webp"

  const handleDoneStep = () => {
    if (typeof toggleCompleted === "function" && !isDone) {
      toggleCompleted(step, true)
    }
  }

  return (
    <StepWrapper
      {...props}
      title={t("dashboard:AddTrackingPage")}
      mediaNode={<VideoThumbnail
        videoLength={videoTime}
        thumbnailUrl={""}
        onClick={() => setVideoModalShow(true)}
      />}
    >
      <Trans ns="dashboard" i18nKey="AddTrackingPageDesc">
        <div className={"flex flex-wrap gap-[var(--p-space-button-group-gap)] items-center my-1"}>
          <div className={classNames(styles.disableInput, "w-[400px] max-w-full")}>
            <TextField
              value={`https://${userInfoSore.data.last_track_url}`}
              disabled
              readOnly
              autoComplete={"off"}
              label={""}
            />
          </div>

          <Tooltip content={t("dashboard:ViewTrackingPage")}>
            <Button icon={ViewIcon} onClick={() => openLink(`https://${userInfoSore.data.last_track_url}`)} />
          </Tooltip>

          <Tooltip content={t("common:CopyLink")}>
            <Button onClick={() => {
              if (userInfoSore.data.last_track_url) {
                copy(getShortTrackLink(userInfoSore.data.last_track_url))
                toast(t("common:CopiedSuccessfully"), 2e3)
              }
            }} icon={ClipboardIcon}
            />
          </Tooltip>
        </div>
      </Trans>
      <div className={"my-[var(--p-space-300)] flex items-center"}>
        <ButtonGroup>
          <Button url={`https://${userInfoSore.data.store_real_name}/admin/menus`} variant={!props.isDone ? "primary" : undefined} external>
            {t("dashboard:DashboardEdgeTable.TrackingPage.action")}
          </Button>
          {
            !isDone && <Button external onClick={handleDoneStep} variant="tertiary">
              {t("dashboard:AddTrackingPagePlainButtonText")}
            </Button>
          }
        </ButtonGroup>
      </div>

      <Modal
        size="large"
        open={videoModalShow}
        title={t("dashboard:DashboardEdgeTable.TrackingPage.action")}
        onClose={() => setVideoModalShow(false)}
      >
        <video
          autoPlay
          controls
          className={"w-full"}
          // poster={posterUrl} // 去除海报图，在ios下自动播放会导致黑屏播放
          src={videoUrl}
          preload="auto"
        />
      </Modal>
    </StepWrapper>
  )
}

export default AddTrackingPage

