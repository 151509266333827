import { BannerProps } from "@shopify/polaris/build/ts/latest/src/components/Banner/Banner"
import dayjs from "dayjs"

import { INewUserGuideLog, IUserConfig, IUserConfigRes } from "@/api/app/type"
import { SpecialOfferType } from "@/models/IUserInfo"
import baseRequest from "@/utils/baseRequest"

import request from "../../utils/request"

export type typeBanner = {
  id: number,
  is_close: boolean,
  action: BannerProps["action"]
  canDismiss: boolean
  content: string
  icon: string
  status: string
  title: string
}


interface SurveyData {
  survey: Survey
}

export interface Survey {
  show: boolean
  status_code: number
  delay_trigger_seconds: number
  client_id_original: string
  client_id: string
  external_user_id: string
  sid: string
  channel_id: string
  width: string
  parameters: Parameters
}

export interface Parameters {
  shop_domain: string
}


export default {
  setBanner(banner_id: number, params: object) {
    return request.post(`/banner/${banner_id}`, params)
  },

  closePopup(popup_id: number) {
    return request.post(`/popup/${popup_id}`)
  },

  shopifyAuthLink(shop: string) {
    return request.put("/oauth/shopify", { shop })
  },

  /**
   * 获取Canny JWT
   */
  getCannyJWT() {
    return baseRequest.get("/v3/integration/canny")
  },


  /**
   * 获取广告信息
   */
  advertisingData() {
    return request.get("p-pic/data")
  },


  /**
   * 关闭广告弹框
   */
  advertisingPopupClose(adId = 0) {
    return request.post("p-pic/close", { adId })
  },


  getConfig() {
    return request.get<IUserConfigRes>("config")
  },


  /**
   * 记录用户日志
   */
  newUserGuideLog(param: INewUserGuideLog, enterTime: number = 0) {
    if (enterTime > 0) param.spend_time = dayjs()
      .unix() - enterTime
    return request.post("new-user-guide/log", param)
  },


  /**
   * 发送反馈
   * @param email
   * @param msg
   * @param type  例如：index-back
   * @param star
   */
  sendFeedback(email: string, msg: string, type: string, star: number) {
    return request.post("dashboard/send-feedback", {
      type: type,
      // 改版 后就没有星级了
      user_email: email,
      // index-back
      user_star: star,
      user_textarea: msg,
    })
  },


  /**
   * 设置用户配置项
   * @param data
   */
  setConfig(data: Partial<IUserConfig>) {
    return request.put("config", data)
  },


  /**
   * 跳过新用户引导
   */
  skipNewUserGuide() {
    return request.post("new-user-guide/skip", {
      _t: dayjs()
        .unix(),
    })
  },


  userInfo() {
    return baseRequest.get("v2/base_config")
  },

  /**
   * 设置Dashboard Special Offer状态
   * @param status
   */
  setSpecialOffer(status: SpecialOfferType) {
    return baseRequest.put("v2/dashboard/special_offer", {
      special_offer: status,
    })
  },

  survey() {
    return baseRequest.get<SurveyData>("v3/common/survey")
  },

  surveyMarkClose() {
    return baseRequest.post("v3/common/survey/mark_close")
  },

  surveyMarkSubmit() {
    return baseRequest.post("v3/common/survey/mark_submit")
  },

  dispatchMarketingBanner(position: "dashboard" | "billing") {
    return baseRequest.post("v3/billing/discount/v2024-04/banner", {
      position,
    })
  },

  closeBillingUpdateBanner() {
    return baseRequest.post("v3/billing/pricing_update/close_banner")
  },
}
