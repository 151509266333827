import {
  Card,
  Link,
  SkeletonBodyText,
  SkeletonDisplayText,
  Text,
} from "@shopify/polaris"
import React from "react"
import { Trans, useTranslation } from "react-i18next"

import { DividerContainer, TitleSwitch } from "@/component"
import CardTitle from "@/component/TitleGroup/CardTitle"
import TitleGroup from "@/component/TitleGroup/TitleGroup"

export default function SkeletonGeneral() {
  const { t } = useTranslation("settings")
  
  return (
    <div className={"flex flex-col gap-4"}>
      <Card>
        <CardTitle title={t("OrderStatusPageTracking.title")} />

        <div className={"mt-4"}></div>
        <DividerContainer>
          <TitleGroup
            className={"pb-4"}
            title={t("OrderStatusPageTracking.ReplaceTrackingLink.title")}
            titleRight={<div className={"w-8 h-5 rounded-md bg-[#E3E3E3]"} />}
            subTitle={<Trans
              ns="settings"
              i18nKey="OrderStatusPageTracking.ReplaceTrackingLink.p1"
            >
              <Link
                url="https://docs.parcelpanel.com/shopify/tracking-page/update-shopify-tracking-link/"
                removeUnderline
                external
              />
            </Trans>}
          />

          <TitleGroup
            className={"pt-4 pb-3"}
            title={t("DeliveryNotifications.TriggerShopifyNativeNotifications.title")}

            titleRight={<div className={"w-8 h-5 rounded-md bg-[#E3E3E3]"} />}
            subTitle={<Trans ns="settings" i18nKey="DeliveryNotifications.TriggerShopifyNativeNotifications.content.t1">
              <Link url="https://docs.parcelpanel.com/shopify/notifications/shipping-tracking-events/" removeUnderline
                external
              />
            </Trans>}
          >
            <div className={"mt-2"}>
              <SkeletonBodyText lines={2} />
            </div>
          </TitleGroup>

          <TitleGroup
            className={"pt-4"}
            title={t("OrderStatusPageTracking.AddAnotherTrackingLink.title")}
            titleRight={<div className={"w-16"}><SkeletonDisplayText size="small" /></div>}
            subTitle={<SkeletonBodyText lines={1} />}
          >
            <div className={"mt-3 flex flex-col gap-3"}>
              <SkeletonBodyText lines={8} />
            </div>
          </TitleGroup>
        </DividerContainer>
      </Card>

      <Card>
        <CardTitle title={t("DropShippingMode.title")} />

        <div className={"mt-4"}>
          <TitleSwitch title={t("DropShippingMode.DropShippingLink.title")} disabled checked={false} />

          <div className="mt-1">
            <Text as={"p"} tone={"subdued"}>
              <Trans ns="settings" i18nKey="DropShippingMode.DropShippingLink.p1">
                <Link
                  url={"https://docs.parcelpanel.com/article/40"}
                  removeUnderline
                  external
                />
              </Trans>
            </Text>
          </div>
        </div>
      </Card>

      <Card>
        <CardTitle title={t("PreferredLanguage.title")} description={t("PreferredLanguage.content")} />
        <div className={"mt-4"}>
          <div style={{ width: 451, maxWidth: "100%" }}>
            <SkeletonBodyText lines={1} />
          </div>
        </div>
      </Card>

      <Card>
        <CardTitle title={t("CourierMatching.title")} description={t("CourierMatching.content")} />
        <div className={"mt-4"}>
          <SkeletonBodyText lines={12} />
        </div>
      </Card>
    </div>
  )
}
