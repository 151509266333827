import { SkeletonBodyText, TextField } from "@shopify/polaris"
import React, { Suspense, useCallback, useState } from "react"
import { useTranslation } from "react-i18next"

import DynamicLoadError from "@/component/DynamicLoadError/DynamicLoadError"

import styles from "./InputTwoThumbsRange.module.scss"

// import TwoThumbsRange from "@/component/Range"
const TwoThumbsRange = React.lazy(() => import("@/component/Range/TwoThumbsRange"))

interface IInputTwoThumbsRangeProps {
  value?: [number, number]
  min?: number
  max?: number

  onChange(value: [number, number], isRefresh?:boolean): void
}

/**
 * 带输入框的 TwoThumbsRange
 */
export function InputTwoThumbsRange({
  value: valueProp,
  min = 1,
  max = 120,
  onChange,
}: IInputTwoThumbsRangeProps) {

  const rangeValue: [number, number] = valueProp || [min, max]

  const [intermediateTextFieldValue, setIntermediateTextFieldValue] = useState<[number, number]>(rangeValue)


  const handleRangeSliderChange = useCallback(
    (value:[number, number]) => {
      setIntermediateTextFieldValue(value)
      onChange(value)
    },
    [],
  )

  const handleLowerTextFieldChange = useCallback(
    (value: string) => {
      let num = parseInt(value || "1", 10)

      if (num > 120) num = 120

      if (rangeValue[0] === num) {
        return
      }
      const [, upperValue] = rangeValue
      const _rangeValue: [number, number] = [num, upperValue]

      setIntermediateTextFieldValue(_rangeValue)
      onChange(_rangeValue)
    },
    [rangeValue, max],
  )

  const handleUpperTextFieldChange = useCallback(
    (value:string) => {
      let num = parseInt(value || "1", 10)

      if (num > 120) num = 120

      if (rangeValue[1] === num) {
        return
      }
      const [lowerValue] = rangeValue
      const _rangeValue: [number, number] = [lowerValue, num]

      setIntermediateTextFieldValue(_rangeValue)
      onChange(_rangeValue)
    },
    [rangeValue, min],
  )


  const lowerTextFieldValue =
    !valueProp || intermediateTextFieldValue[0] === rangeValue[0]
      ? rangeValue[0]
      : intermediateTextFieldValue[0]

  const upperTextFieldValue =
    !valueProp || intermediateTextFieldValue[1] === rangeValue[1]
      ? rangeValue[1]
      : intermediateTextFieldValue[1]

  const { t } = useTranslation("orders")

  return (
    <>
      <div className={styles.TwoThumbsRange__Padding}>
        <DynamicLoadError>
          <Suspense fallback={<SkeletonBodyText lines={1} />}>
            <TwoThumbsRange
              step={1}
              min={min}
              max={max}
              values={rangeValue}
              onChange={handleRangeSliderChange}
            />
          </Suspense>
        </DynamicLoadError>
      </div>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className={styles.TextField__Width}>
          <TextField
            label={t("MinDay")}
            type="number"
            value={`${lowerTextFieldValue}`}
            step={1}
            min={min}
            max={Math.min(rangeValue[1], max)}
            onChange={handleLowerTextFieldChange}
            autoComplete="off"
          />
        </div>
        <div className={styles.TextField__Width}>
          <TextField
            label={t("MaxDay")}
            type="number"
            value={`${upperTextFieldValue}`}
            step={1}
            min={Math.max(min, rangeValue[0])}
            max={max}
            onChange={handleUpperTextFieldChange}
            autoComplete="off"
          />
        </div>
      </div>
    </>
  )
}
