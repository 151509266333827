import { Divider } from "@shopify/polaris"
import React, { Children } from "react"

export interface DividerContainerProps {
  /** The content to render in the divider container. */
  children?: React.ReactNode;
}

export default function DividerContainer({ children }: DividerContainerProps) {
  return (
    <div>
      {
        Children.map(children, (v, i) => (
          <>
            {i ? <Divider /> : null}
            {v}
          </>
        ))
      }
    </div>
  )
}
