import dayjs from "dayjs"
import { convertToRaw, EditorState } from "draft-js"
// @ts-ignore
import draftToHtml from "draftjs-to-html"
import { cloneDeep } from "lodash-es"
import { action, computed, makeObservable, observable } from "mobx"

import EDDApi from "@/api/settings/preEDD"
import { DefaultRuleI, RuleItem, RulesDataType, translation, WidgetData } from "@/api/settings/type/preEdd"
import ShopifyGraphql from "@/api/shopify-graphql/shopify-graphql"
import { IContextualSaveBar } from "@/models/ICommonStore"
import { getLanguagesList } from "@/pages/Settings/EmailEdit/utils"
import { RulesType } from "@/pages/Settings/PrePurchaseEDD/type"
import { stringToEditorState } from "@/pages/Settings/PrePurchaseEDD/utils"
import {
  initModulesStore,
  registerListener,
  registerModuleListener,
  setInitOptions,
} from "@/pages/Settings/PrePurchaseEDD/utils/listener"
import WidgetListener from "@/pages/Settings/PrePurchaseEDD/utils/widgetListener"
import { defaultEDDModuleConfig } from "@/stores/tracking_page/defaultConfig"

function splitArrayIntoChunks(array:any[], chunkSize: number) {
  const result = []

  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize))
  }
  return result
}

function removeItems(arr: RuleItem[], idsToRemove: string[]) {
  return arr.filter(item => !idsToRemove.includes(item.gid.toString()))
}

export default class PrePurchaseEDD {
  @observable loadedDataTime = 0
  @observable loading = false
  // app block开启状态
  @observable appBlockActive = false
  // 添加链接
  @observable addEddWidgetLink = ""

  @observable public saveBar: IContextualSaveBar = {
    disabled: false,
    loading: false,
    show: false,
    unlocking: false,
  }
  @observable public widgetSaveBar: IContextualSaveBar = {
    disabled: false,
    loading: false,
    show: false,
    unlocking: false,
  }

  @observable public widgetData: WidgetData["widget"] = {
    enabled: false,
    localized_settings: {},
    default: {
      localized_settings: {},
      colors: {
        border: "",
      },
    },
  }

  // editorState数据集合，必须要准备sting map和editorState map两种存储
  @observable public editorStateMap: Record<string, EditorState | null> = {}

  // 当前选择语言
  @observable public activeLanguage = "en"

  // 规则数据集加载中
  @observable public rulesLoading: boolean = false

  // 规则数据集
  @observable public rulesData: RulesDataType = {
    default_rule: { ...defaultEDDModuleConfig, non_business_day_periods: [] }, // 默认default
  }

  // specific rules列表的排序规则
  @observable public specificSortType: "Ascending" | "Descending" = "Descending"

  @action.bound
  public setSpecificSortType(value: "Ascending" | "Descending") {
    this.specificSortType = value
  }

  @observable public specificSearchValue: string = ""

  @action.bound
  public changeSpecificSearchValue(value: string) {
    this.specificSearchValue = value
  }

  @observable public specificScope: string[] = []
  @action.bound
  public changeSearchSpecificScope(value: string[]) {
    this.specificScope = value
  }

  @observable public exclusionSearchValue: string = ""

  @action.bound
  public changeExclusionSearchValue(value: string) {
    this.exclusionSearchValue = value
  }

  @observable public exclusionScope: string[] = []
  @action.bound
  public changeSearchExclusionScope(value: string[]) {
    this.exclusionScope = value
  }


  constructor() {
    makeObservable(this)
  }

  @action.bound
  public setSaveBar<K extends keyof IContextualSaveBar>(key: K, value: IContextualSaveBar[K]) {
    this.saveBar[key] !== value && (this.saveBar[key] = value)
  }

  @action.bound
  public setWidgetSaveBar<K extends keyof IContextualSaveBar>(key: K, value: IContextualSaveBar[K]) {
    this.widgetSaveBar[key] !== value && (this.widgetSaveBar[key] = value)
  }


  // 语言切换列表
  @computed get languagesList() {
    const list = getLanguagesList(Object.keys(this.widgetData.localized_settings), "en")

    return list.map(item => ({ label: item.name, value: item.value }))
  }

  // 语言切换列表
  @computed get activeLanguageLabel() {
    return this.languagesList.find(item => item.value === this.activeLanguage)?.label
  }
  // 语言切换列表
  @computed get activeBorderColor() {
    return this.widgetData.localized_settings[this.activeLanguage].colors.border
  }

  // 当前富文本值
  @computed get editorState() {
    return this.editorStateMap[this.activeLanguage]
  }

  // rule 保存的数据
  @computed get ruleUpdateInput() {
    if (this.rulesData.default_rule?.non_business_day_periods.length) {
      this.rulesData.default_rule.non_business_day_periods = this.rulesData.default_rule?.non_business_day_periods
        .filter(v => v)
    }

    return this.rulesData
  }

  // 当前Specific规则总列表
  @computed get currentSpecificRules() {
    const rules = (this.rulesData.specific_rules || []).filter(item =>
      item.title?.toLowerCase().includes(this.specificSearchValue.toLowerCase()),
    )

    const filterRules = this.specificScope.length === 1 ? rules.filter(item => this.specificScope.includes(String(item.scope))) : rules

    return filterRules.sort(
      (ruleA, ruleB) => this.specificSortType === "Ascending"
        ? ruleA.processing_time - ruleB.processing_time
        : ruleB.processing_time - ruleA.processing_time)
  }

  // 当前Specific规则总列表
  @computed get specificProductCount() {
    const rules = this.rulesData.specific_rules || []

    return  rules.filter(item => item.title?.includes(this.specificSearchValue))
      .filter(item => item.scope === 0).length
  }
  // 当前Specific规则总列表
  @computed get specificCollectionCount() {
    const rules = this.rulesData.specific_rules || []

    return  rules.filter(item => item.title?.includes(this.specificSearchValue))
      .filter(item => item.scope === 1).length
  }

  // 当前Exclusion规则总列表
  @computed get currentExclusionRules() {
    const rules = (this.rulesData.exclusion_rules || []).filter(item =>
      item.title?.toLowerCase().includes(this.exclusionSearchValue.toLowerCase()),
    )

    return this.exclusionScope.length === 1 ? rules.filter(item => this.exclusionScope.includes(String(item.scope))) : rules
  }

  // 当前Exclusion规则总列表
  @computed get exclusionProductCount() {
    const rules = this.rulesData.exclusion_rules || []

    return  rules.filter(item => item.title?.includes(this.exclusionSearchValue))
      .filter(item => item.scope === 0).length
  }
  // 当前Exclusion规则总列表
  @computed get exclusionCollectionCount() {
    const rules = this.rulesData.exclusion_rules || []

    return  rules.filter(item => item.title?.includes(this.exclusionSearchValue))
      .filter(item => item.scope === 1).length
  }

  @action.bound
  setData(key: string, data: any) {
    // @ts-ignore
    this[key] = data
  }

  /**
   * 获取AppBlockStatus状态
   * @param calls
   */
  @action.bound
  getAppBlockStatus(calls?:()=>void):void{
    EDDApi.getAppBlockStatus().then(({ data }) => {
      this.appBlockActive = data.app_block_active_state.enabled
      this.addEddWidgetLink = data.app_block_active_state.add_widget_link
    })
      .finally(() => calls && calls())
  }

  /**
   * 获取AppBlockStatus状态
   * @param calls
   */
  @action.bound
  getWidgetConfig(calls?:()=>void):void{
    EDDApi.getWidgetConfig().then(({ data }) => {
      WidgetListener.destroyListener()
      this.widgetData = data.widget

      // 初始化editorStateList
      const { localized_settings } = data.widget
      const editorStateMap:Record<string, EditorState | null> = {}

      Object.keys(localized_settings).map((key) => {
        editorStateMap[key] = stringToEditorState(localized_settings[key].translations.content || "")
      })

      this.editorStateMap = editorStateMap

      WidgetListener.setInitOptions(localized_settings)
      WidgetListener.registerListener()
    })
      .finally(() => calls && calls())
  }

  /**
   * 获取AppBlockStatus状态
   * @param calls
   */
  @action.bound
  getRules(calls?: () => void): void {
    this.rulesLoading = true
    Promise.all([
      EDDApi.getDefaultRule(),
      EDDApi.getExclusionRule(),
      EDDApi.getSpecificRule(),
    ])
      .then(async ([{ data: defaultRule }, { data: exclusionRule }, { data: specificRule }]) => {
        console.log(dayjs().unix())
        this.loadedDataTime = dayjs().unix()
        const specificRuleIds = specificRule.specific_rules?.map(item => item.gid)
        const exclusionRuleIds = exclusionRule.exclusion_rules?.map(item => item.gid)

        const specificRuleIdsChunkedArray = splitArrayIntoChunks(specificRuleIds || [], 250)
        const exclusionRuleIdsChunkedArray = splitArrayIntoChunks(exclusionRuleIds || [], 250)

        // 创建一个数组来存储所有的 Promise
        const promises: any[] = []

        specificRuleIdsChunkedArray.forEach((ids, index) => {
          const promise = ShopifyGraphql.getProductAndCollectionList(ids).then(({ data }) => {
            if (data?.nodes) {
              data.nodes.forEach((node, idIndex) => {
                const targetIndex = index * 250 + idIndex
                const targetRule = specificRule.specific_rules?.[targetIndex]

                if (targetRule) {
                  targetRule.title = node.title
                  targetRule.image = targetRule.scope ? node.image ? node.image.url : ""
                    : node.featuredMedia ? node.featuredMedia.preview.image.url : ""
                }
              })
            }
          })

          promises.push(promise)
        })

        exclusionRuleIdsChunkedArray.forEach((ids, index) => {
          const promise = ShopifyGraphql.getProductAndCollectionList(ids).then(({ data }) => {
            if (data?.nodes) {
              data.nodes.forEach((node, idIndex) => {
                const targetIndex = index * 250 + idIndex
                const targetRule = exclusionRule.exclusion_rules?.[targetIndex]

                if (targetRule) {
                  targetRule.title = node.title
                  targetRule.image = targetRule.scope ? node.image ? node.image.url : ""
                    : node.featuredMedia ? node.featuredMedia.preview.image.url : ""
                }
              })
            }
          })

          promises.push(promise)
        })

        // 等待所有的 Promise 完成
        await Promise.all(promises)

        const rulesData = {
          ...defaultRule,
          ...exclusionRule,
          ...specificRule,
        }

        this.rulesData = rulesData
        this.rulesLoading = false
        setInitOptions(rulesData)
        registerListener()

        initModulesStore()
        registerModuleListener()
      })
      .finally(() => calls && calls())
  }

  @action.bound
  setDefaultRule<T extends keyof DefaultRuleI>(key: T, value: DefaultRuleI[T]) {
    this.rulesData.default_rule && (this.rulesData.default_rule[key] = value)
  }

  @action.bound
  addExclusionRules(list: RuleItem[]) {
    this.rulesData.exclusion_rules = [...list.filter(item => item.checked), ...(this.rulesData.exclusion_rules || [])]
  }

  @action.bound
  addSpecificRules(list: RuleItem[]) {
    this.rulesData.specific_rules = [...list.filter(item => item.checked), ...(this.rulesData.specific_rules || [])]
  }

  // 编辑editSpecificRules action
  @action.bound
  editSpecificRules(list: RuleItem[]) {
    const specific_rules = [...(this.rulesData.specific_rules || [])]

    // 替换、插入或删除项
    list.forEach(item => {
      const index = specific_rules.findIndex(rule => rule.gid === item.gid)

      if (item.checked) {
        // 如果 checked 为 true，处理替换或插入
        if (index !== -1) {
          if (item.processing_time === undefined){
            item.processing_time = specific_rules[index].processing_time
          }
          // 替换已有项
          specific_rules[index] = item
        } else {
          // 默认为1
          item.processing_time = 1
          // 插入到数组头部
          specific_rules.unshift(item)
        }
      } else {
        // 如果 checked 为 false，处理删除
        if (index !== -1) {
          specific_rules.splice(index, 1) // 删除项
        }
      }
    })

    // 更新 rulesData
    this.rulesData.specific_rules = specific_rules
  }

  @action.bound
  editExclusionRules(list: RuleItem[]) {
    const exclusion_rules = [...(this.rulesData.exclusion_rules || [])]

    // 替换、插入或删除项
    list.forEach(item => {
      const index = exclusion_rules.findIndex(rule => rule.gid === item.gid)

      if (item.checked) {
        // 如果 checked 为 true，处理替换或插入
        if (index !== -1) {
          // 替换已有项
          exclusion_rules[index] = item
        } else {
          // 插入到数组头部
          exclusion_rules.unshift(item)
        }
      } else {
        // 如果 checked 为 false，处理删除
        if (index !== -1) {
          exclusion_rules.splice(index, 1) // 删除项
        }
      }
    })

    // 更新 rulesData
    this.rulesData.exclusion_rules = exclusion_rules
  }


  /**
   * 设置Widget enabled设置
   * @param checked
   */
  @action.bound
  async setWidgetEnabled(checked: boolean){
    return new Promise((resolve, reject) => {
      EDDApi.setWidgetConfig({
        widget: { enabled: checked },
      }).then(() => {
        this.widgetData.enabled = checked
        resolve(checked)
      })
        .catch(() => {
          reject()
        })
    })
  }

  /**
   * 设置当前语言
   */
  @action.bound
  setActiveLanguage(code:string){
    this.activeLanguage = code
  }
  /**
   * 设置富文本的值
   */
  @action.bound
  onEditorStateChange(editorState: EditorState){
    function removeTrailingNewline(str: string) {
      if (str.endsWith("\n")) {
        return str.slice(0, -1) // 去掉最后一个字符
      }
      return str // 如果没有换行符，返回原字符串
    }

    // 需要同时更改translations中的String和存储的editorStateMap
    const base = removeTrailingNewline(this.widgetData.localized_settings[this.activeLanguage].translations.content)
    const now = removeTrailingNewline(draftToHtml(convertToRaw(editorState.getCurrentContent())))

    if (base !== now){
      this.widgetData.localized_settings[this.activeLanguage].translations.content = now
    }
    this.editorStateMap[this.activeLanguage] = editorState
  }
  /**
   * 设置富文本Border值
   */
  @action.bound
  onBorderColorChange(value: string){
    this.widgetData.localized_settings[this.activeLanguage].colors.border = value
  }
  /**
   * 富文本初始化
   */
  @action.bound
  onWidgetRevertToDefault(){
    const defaultConfig = cloneDeep(this.widgetData.default)

    const localized_settings: translation = {}

    for (const code in defaultConfig.localized_settings){
      const { translations } = defaultConfig.localized_settings[code]

      localized_settings[code] = {
        translations,
        colors: defaultConfig.colors,
      }
    }

    const editorStateMap:Record<string, EditorState | null> = {}

    Object.keys(localized_settings).map((key) => {
      editorStateMap[key] = stringToEditorState(localized_settings[key].translations.content || "")
    })

    // WidgetListener.destroyListener()
    // WidgetListener.setInitOptions(localized_settings)
    for (const key in localized_settings){
      this.widgetData.localized_settings[key] = localized_settings[key]
    }
    this.widgetData.localized_settings = localized_settings
    this.editorStateMap = editorStateMap
    // WidgetListener.registerListener()
  }

  /**
   * Delete rules
   */
  @action.bound
  handleDeleteRules(type: RulesType, id: string[]) {
    if (type === RulesType.SpecificRule && this.rulesData.specific_rules) {
      this.rulesData.specific_rules = removeItems(this.rulesData.specific_rules, id)
    }
    if (type === RulesType.ExclusionRule && this.rulesData.exclusion_rules) {
      this.rulesData.exclusion_rules = removeItems(this.rulesData.exclusion_rules, id)
    }
  }
  /**
   * Delete all rules
   */
  @action.bound
  handleDeleteALLRules(type: RulesType) {
    if (type === RulesType.SpecificRule) {
      this.rulesData.specific_rules = []
    }
    if (type === RulesType.ExclusionRule) {
      this.rulesData.exclusion_rules = []
    }
  }

  @action.bound
  saveChangeSpecificRules(result: {
    add: RuleItem[],
    missing: string[],
    unchanged: string[],
    businessDays?: number,
  }) {
    let specificRules = this.rulesData.specific_rules || []

    // 移除操作
    specificRules = removeItems(specificRules, result.missing)

    // 修改操作
    if (result.businessDays !== undefined){
      specificRules.forEach(item => {
        if (result.unchanged.includes(item.gid)){
          item.processing_time = result.businessDays
        }
      })
    }

    // 添加操作
    this.rulesData.specific_rules = [...result.add, ...specificRules]
  }

  @action.bound
  saveChangeExclusionRules(result: {
    add: RuleItem[],
    missing: string[],
  }) {
    let exclusionRules = this.rulesData.exclusion_rules || []

    // 移除操作
    exclusionRules = removeItems(exclusionRules, result.missing)
    // 增加操作
    this.rulesData.exclusion_rules = [...result.add, ...exclusionRules]
  }
}
