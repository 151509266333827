import { Banner } from "@shopify/polaris"
import { HeartIcon } from "@shopify/polaris-icons"
import React from "react"
import { useTranslation } from "react-i18next"

import { APICode } from "@/api/APICode"
import dashboard from "@/api/dashboard"
import stores from "@/stores"

/**
 * 合作伙伴友好提示框
 */
function PartnerFriendlyBanner() {
  const { t } = useTranslation("common")

  let text_message = ""
  const checkT = stores.userInfoSore.data.is_show_partner_friendly_banner ?? 0

  if (checkT === 1) {
    text_message = t("PartnerFriendlyBanner.content_1")
  }
  if (checkT === 2) {
    text_message = t("PartnerFriendlyBanner.content_2")
  }

  return (
    <Banner
      icon={HeartIcon}
      onDismiss={
        () => {
          dashboard.closePartnerFriendly({ checkT })
            .then(({ data: { code } }) => {
              if (code === APICode.OK) {
                if (checkT === 1) {
                  stores.userInfoSore.setDataSingle("notice_partner_f", 1)
                } else if (checkT === 2) {
                  stores.userInfoSore.setDataSingle("notice_partner_d", 1)
                }
              }
            })
        }
      }
    >
      <p>{text_message}</p>
    </Banner>
  )
}

export default PartnerFriendlyBanner
