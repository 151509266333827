import {
  Badge,
  Button,
  Card, Divider,
  Link,
  SkeletonBodyText,
  SkeletonDisplayText,
  Text,
} from "@shopify/polaris"
import dayjs from "dayjs"
import React, { Suspense } from "react"
import { Trans, useTranslation } from "react-i18next"

import CardTitle from "@/component/TitleGroup/CardTitle"
import { initModulesStore, saveModulesConfig } from "@/pages/Settings/PrePurchaseEDD/utils/listener"
import EditFulfillmentWorkflow from "@/pages/TrackingPage/EstimatedDeliveryTime/components/EditFulfillmentWorkflow"
import ExcludeDaysModule from "@/pages/TrackingPage/EstimatedDeliveryTime/components/ExcludeDaysModule"

export default function SkeletonPrePurchaseEDD() {
  const { t } = useTranslation("settings")
  
  return (
    <div className={"flex flex-col gap-4"}>
      <div className={"mb-2"}>
        <SkeletonBodyText lines={5} />
      </div>
      <Card padding="0">
        <div className={"p-4"}>
          <CardTitle
            title={<div className={"flex gap-1"}><div>{t("PrePurchaseEDDModule.Display")}</div></div>}
            description={
              <Trans ns={"settings"} i18nKey={"PrePurchaseEDDModule.DisplayDesc"}>
                <Link url={"#"} target={"_blank"} removeUnderline />
              </Trans>
            }
          />
        </div>
        <Divider />
        <div className={"p-4 flex flex-wrap justify-between gap-2"}>
          <SkeletonBodyText lines={12} />
        </div>
      </Card>

      <Card>
        <CardTitle title={<div className={"w-24"}><SkeletonDisplayText /></div>} />
        <div className={"mt-4 flex flex-col gap-3"}>
          <SkeletonBodyText lines={22} />
        </div>
      </Card>

      <Card>
        <CardTitle title={<div className={"w-24"}><SkeletonDisplayText /></div>} />
        <div className={"mt-4 flex flex-col gap-3"}>
          <SkeletonBodyText lines={17} />
        </div>
      </Card>

      <Card>
        <CardTitle title={<div className={"w-24"}><SkeletonDisplayText /></div>} />
        <div className={"mt-4 flex flex-col gap-3"}>
          <SkeletonBodyText lines={17} />
        </div>
      </Card>
    </div>
  )
}
