export const DateFormat = "YYYY-MM-DD"

export const VALID_YYYY_MM_DD_DATE_REGEX = /^\d{4}-\d{1,2}-\d{1,2}/

export function isDate(date: string | number | Date) {
  return !isNaN(new Date(date).getDate())
}
export function isValidYearMonthDayDateString(date: string) {
  return VALID_YYYY_MM_DD_DATE_REGEX.test(date) && isDate(date)
}
export function isValidDate(date: string) {
  return date.length === 10 && isValidYearMonthDayDateString(date)
}
