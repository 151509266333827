import { Box, Text } from "@shopify/polaris"
import { BoxProps } from "@shopify/polaris/build/ts/src/components/Box/Box"
import React from "react"

interface CustomPageProps {
  title: string | React.ReactNode,
  paddingBlockStart?: BoxProps["paddingBlockStart"]
  paddingBlockEnd?: BoxProps["paddingBlockStart"]
  padding?: BoxProps["padding"]
  rightChildren: React.ReactNode
  children?: React.ReactNode
}

const defaultPaddingBlock:BoxProps["paddingBlockStart"] = { xs: "400", sm: "400", md: "600", lg: "600", xl: "600" }

export default function CustomPage({
  title,
  paddingBlockStart = defaultPaddingBlock,
  paddingBlockEnd = defaultPaddingBlock,
  rightChildren,
  padding,
  children,
}:CustomPageProps){
  return <>
    <Box
      paddingBlockStart={paddingBlockStart}
      paddingBlockEnd={paddingBlockEnd}
      padding={padding}
    >
      <div className="flex justify-between items-center flex-wrap">
        <>
          <Text variant="headingLg" as="h1">
            {title}
          </Text>

          {rightChildren}
        </>
      </div>
    </Box>
    {children}
  </>
}
