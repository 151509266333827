import { Button, Modal } from "@shopify/polaris"
import React, { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"

import accountApi from "@/api/account"
import ApplyCode from "@/pages/Account/components/ApplyCode"
import stores from "@/stores"


export interface ApplyDiscountCodeModalProps {
  open: boolean
  onClose: () => void
  t: any
}

export const ApplyDiscountCodeModal: React.FC<ApplyDiscountCodeModalProps> = ({
  open,
  onClose,
  t,
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      title={t("account:ApplyDiscountCode")}
      secondaryActions={[
        {
          content: t("common:Close"),
          onAction: onClose,
        },
      ]}
    >
      <Modal.Section>
        <ApplyCode />
      </Modal.Section>
    </Modal>
  )
}

export const useApplyDiscountCodeModal = () => {
  const [isOpen, setIsOpen] = useState(false)
  const openModalAndInitDiscountCode = useCallback(() => {
    setIsOpen(true)

    // 如果折扣码接口未初始化则请求接口
    if (!stores.userInfoSore.discount.initialized) {
      accountApi.getDiscountCode()
        .then(({ data }) => {
          stores.userInfoSore.setDiscount(data.current_discount)
        })
    }
  }, [])
  const closeModal = useCallback(() => setIsOpen(false), [])

  return {
    isOpen,
    openModalAndInitDiscountCode,
    closeModal,
  }
}

const ApplyDiscountCode = () => {
  const { t } = useTranslation(["account", "common"])
  const { isOpen, openModalAndInitDiscountCode, closeModal } = useApplyDiscountCodeModal()

  return <>
    <Button variant="tertiary" onClick={openModalAndInitDiscountCode}>{t("account:ApplyDiscountCode")}</Button>

    <ApplyDiscountCodeModal open={isOpen} onClose={closeModal} t={t} />
  </>
}

export default ApplyDiscountCode
