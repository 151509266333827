import CommonStore from "@/stores/CommonStore"
import EventsStore from "@/stores/dashboard/Events"
import DeliveryGuarantee from "@/stores/deliveryGuarantee"
import EmailEditStore from "@/stores/emailEdit"
import ModulesStore from "@/stores/emailEdit/modules"
import GlobalMenuStore from "@/stores/GlobalMenuStore"
import OrdersStore from "@/stores/orders/Orders"
import OrderTagsManageStore from "@/stores/OrderTagsManageStore"
import PrePurchaseEDDStore from "@/stores/PrePurchaseEDD/index"
import ProductAndCollectionStore from "@/stores/PrePurchaseEDD/ProductAndCollectionStore"
import SettingsPageStore from "@/stores/settingPage/index"
import EDDModulesStore from "@/stores/tracking_page/modules"
import ProductCollection from "@/stores/tracking_page/ProductCollectionStore"
import TrackingPageStore from "@/stores/tracking_page/TrackingPageStore"
import UpgradeStore from "@/stores/UpgradeStore"

import ProductRecommendationDataStore from "./dashboard/ProductRecommendationDataStore"
import ShipmentsLookupsDataStore from "./dashboard/ShipmentsLookupsDataStore"
import ShippingNotificationsDataStore from "./dashboard/ShippingNotificationsDataStore"
import StatisticsStore from "./dashboard/StatisticsStore"
import Post from "./Post"
import UserInfoStore from "./userInfo"

export default {
  EventsStore: new EventsStore(),
  Post: new Post(),
  commonStore: new CommonStore(),
  globalMenuStore: new GlobalMenuStore(),
  // Orders
  ordersStore: new OrdersStore(),
  orderTagsManageStore: new OrderTagsManageStore(),
  productCollection: new ProductCollection(),
  productRecommendationDataStore: new ProductRecommendationDataStore(),
  // Setting
  settingsPageStore: new SettingsPageStore(),
  ModulesStore: new ModulesStore(),
  EmailEditStore: new EmailEditStore(),
  // Dashboard
  shipmentsLookupsDataStore: new ShipmentsLookupsDataStore(),
  shippingNotificationsDataStore: new ShippingNotificationsDataStore(),
  statisticsStore: new StatisticsStore(),
  // TrackingPage
  trackingPageStore: new TrackingPageStore(),
  eddModulesStore: new EDDModulesStore(),
  upgradeStore: new UpgradeStore(),
  userInfoSore: new UserInfoStore(),
  // DeliveryGuarantee
  DeliveryGuarantee: new DeliveryGuarantee(),
  PrePurchaseEDDStore: new PrePurchaseEDDStore(),
  ProductAndCollectionStore: new ProductAndCollectionStore(),
}

export interface IStores {
  commonStore: CommonStore,
  emailEditStore: EmailEditStore,
  modulesStore: ModulesStore,
  globalMenuStore: GlobalMenuStore,

  userInfoSore: UserInfoStore,

  // Dashboard
  shipmentsLookupsDataStore: ShipmentsLookupsDataStore,
  shippingNotificationsDataStore: ShippingNotificationsDataStore,
  productRecommendationDataStore: ProductRecommendationDataStore,
  statisticsStore: StatisticsStore,

  // TrackingPage
  trackingPageStore: TrackingPageStore,
  eddModulesStore: EDDModulesStore,

  Post: Post,

  // Setting
  settingsPageStore: SettingsPageStore,
  prePurchaseEDDStore: PrePurchaseEDDStore,
  productAndCollectionStore: ProductAndCollectionStore,
}
