import { debounce } from "lodash-es"
import { action, computed, makeObservable, observable } from "mobx"

import ShopifyGraphql from "@/api/shopify-graphql/shopify-graphql"
import { CollectionNode, PageInfo, ProductNode } from "@/api/shopify-graphql/shopify-graphql.type"

export default class productCollection {
  // 是否初始化
  @observable public initialized: boolean = false

  // collections & searchProducts列表加载中
  @observable public searchLoading: boolean = false

  // products/collections 列表
  @observable public products: ProductNode[] = []
  @observable public collections: CollectionNode[] = []

  // search list
  @observable public searchProducts: ProductNode[] = []
  @observable public searchCollections: CollectionNode[] = []

  // search list
  @observable public searchHistoryProducts: ProductNode[] = []
  @observable public searchHistoryCollections: CollectionNode[] = []

  // list page info
  @observable public productsPageInfo: PageInfo = { endCursor: null, hasNextPage: true }
  @observable public collectionsPageInfo: PageInfo = { endCursor: null, hasNextPage: true }

  // search page info  短暂记录搜索的记录，解决搜索过程中勾选找不到的问题
  @observable public searchProductsPageInfo: PageInfo = { endCursor: null, hasNextPage: true }
  @observable public searchCollectionsPageInfo: PageInfo = { endCursor: null, hasNextPage: true }

  // products/collections type 0-products 1-collections
  @observable public tabIndex: 0 | 1 = 0
  @observable public keyword: string = ""
  debouncedSearch
  debouncedHandleScrollBottom

  constructor() {
    makeObservable(this)
    this.debouncedSearch = debounce(this.performSearch, 400)

    this.debouncedHandleScrollBottom = debounce(this.handleScrollBottom, 1000)
  }

  // 初始化查询
  @action.bound
  init(){
    this.keyword = ""
    if (this.initialized) return
    this.searchLoading = true

    ShopifyGraphql.initProductsAndCollections().then(res => {
      if (res.status === 200 && res.data) {
        const { products, collections } = res.data

        this.products = products.nodes
        this.productsPageInfo = products.pageInfo

        this.collections = collections.nodes
        this.collectionsPageInfo = collections.pageInfo
      }
    })
      .finally(() => {
        this.initialized = true
        this.searchLoading = false
      })
  }

  // Product翻页
  @action.bound
  nextPageProduct(query: string = ""){
    const { hasNextPage, endCursor } = query ? this.searchProductsPageInfo : this.productsPageInfo

    if (hasNextPage){
      this.searchLoading = true
      ShopifyGraphql.searchProducts(query, endCursor).then(res => {
        if (res.status === 200 && res.data) {
          const { nodes, pageInfo } = res.data.products

          if (!query){
            this.products = [...this.products, ...nodes]
            this.productsPageInfo = pageInfo
          } else {
            this.searchProducts = [...this.searchProducts, ...nodes]
            this.searchHistoryProducts = [...this.searchHistoryProducts, ...nodes]
            this.searchProductsPageInfo = pageInfo
          }
        }
      })
        .finally(() => {
          this.searchLoading = false
        })
    }
  }

  // Collections翻页
  @action.bound
  nextPageCollection(query: string = ""){
    const { hasNextPage, endCursor } = query ? this.searchCollectionsPageInfo : this.collectionsPageInfo

    if (hasNextPage){
      this.searchLoading = true
      ShopifyGraphql.searchCollections(query, endCursor).then(res => {
        if (res.status === 200 && res.data) {
          const { nodes, pageInfo } = res.data.collections

          if (!query){
            this.collections = [...this.collections, ...nodes]
            this.collectionsPageInfo = pageInfo
          } else {
            this.searchCollections = [...this.searchCollections, ...nodes]
            this.searchHistoryCollections = [...this.searchHistoryCollections, ...nodes]
            this.searchCollectionsPageInfo = pageInfo
          }
        }
      })
        .finally(() => {
          this.searchLoading = false
        })
    }
  }

  // Search products
  @action.bound
  handleSearchProducts(query: string = ""){
    this.searchLoading = true
    ShopifyGraphql.searchProducts(query, null).then(res => {
      if (res.status === 200 && res.data) {
        const { nodes, pageInfo } = res.data.products

        this.searchProducts = nodes
        this.searchHistoryProducts = [...this.searchHistoryProducts, ...nodes]
        this.searchProductsPageInfo = pageInfo
      }
    })
      .finally(() => {
        this.searchLoading = false
      })
  }

  // Search collections
  @action.bound
  handleSearchCollections(query: string = ""){
    this.searchLoading = true
    ShopifyGraphql.searchCollections(query, null).then(res => {
      if (res.status === 200 && res.data) {
        const { nodes, pageInfo } = res.data.collections

        this.searchCollections = nodes
        this.searchHistoryCollections = [...this.searchHistoryCollections, ...nodes]
        this.searchCollectionsPageInfo = pageInfo
      }
    })
      .finally(() => {
        this.searchLoading = false
      })
  }

  @action.bound
  toggleTabIndex(value: 0 | 1){
    if (this.tabIndex !== value){
      this.tabIndex = value
      this.keyword = ""
    }
  }
  @action.bound
  performSearch() {
    // 执行搜索逻辑
    if (this.tabIndex === 0) {
      this.handleSearchProducts(this.keyword)
    } else if (this.tabIndex === 1){
      this.handleSearchCollections(this.keyword)
    }
  }

  @action.bound
  changeKeyword(value: string){
    this.keyword = value
    if (this.searchLoading) return
    this.debouncedSearch()
  }
  // 触底翻页
  @action.bound
  handleScrollBottom(){
    if (this.searchLoading) return
    
    if (this.tabIndex === 0) {
      this.nextPageProduct(this.keyword)
    } else if (this.tabIndex === 1){
      this.nextPageCollection(this.keyword)
    }
  }
  // 清除搜索过程中的记录
  @action.bound
  clearSearchHistory(){
    this.searchHistoryProducts = []
    this.searchHistoryCollections = []
  }

  // 当前Exclusion规则总列表
  @computed get currentList() {
    if (this.tabIndex === 0){
      return this.keyword ? this.searchProducts : this.products
    } else {
      return this.keyword ? this.searchCollections : this.collections
    }
  }
}
