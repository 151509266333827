/**
 * 比较两个数组,返回多出的元素和缺失的元素
 *
 * 参数:
 * arr1 (Array): 原数组
 * arr2 (Array): 新数组
 *
 * 返回:
 * Array: 第一个元素是多出的元素数组,第二个元素是缺失的元素数组
 */
export function compareArrays(arr1:string[], arr2:string[]) {
  const extra = arr2.filter(item => !arr1.includes(item))
  const missing = arr1.filter(item => !arr2.includes(item))
  const unchanged = arr1.filter(item => arr2.includes(item)) // 在两个数组中都存在的元素

  return [extra, missing, unchanged]
}

/**
 * 比较两个字符串数组，是否有变化，无视顺序
 *
 * 参数:
 * arr1 (Array): 数组
 * arr2 (Array): 数组
 *
 * 返回: boolean 是否相等
 */
export function arraysEqual(arr1:string[], arr2:string[]) {
  if (arr1.length !== arr2.length) return false

  // 对两个数组进行排序并比较
  const sortedArr1 = arr1.slice().sort()
  const sortedArr2 = arr2.slice().sort()

  return sortedArr1.every((value, index) => value === sortedArr2[index])
}
