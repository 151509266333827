import { ActionList, Button, Popover } from "@shopify/polaris"
import { Observer } from "mobx-react"
import { useCallback, useMemo, useState } from "react"

import { SupportLanguages } from "@/i18n/SupportLanguages"
import stores from "@/stores"

const LanguageSelect = () => {
  const [active, setActive] = useState(false)

  const toggleActive = useCallback(() => setActive((active) => !active), [])
  const { commonStore } = stores
  const selectOption = useMemo(() => commonStore.languageItems.map(item => {
    return {
      content: item.content,
      onAction: () => {
        commonStore.setActiveLanguage(item.id as SupportLanguages)
        setActive(false)
      },
    }
  }), [commonStore])

  return <Observer>{() => {
    return <div>
      <Popover
        active={active}
        activator={<Button onClick={toggleActive} disclosure>
          {commonStore.selectLanguage}
        </Button>}
        autofocusTarget="first-node"
        onClose={toggleActive}
      >
        <ActionList
          actionRole="menuitem"
          items={selectOption}
        />
      </Popover>
    </div>
  }}</Observer>
}

export default LanguageSelect
