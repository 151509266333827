import dayjs from "dayjs"

import currencyFormat from "./formatMoneny"


/**
 * 输入开始结束日期获取期间日期
 * @param startDateString
 * @param endDateString
 */
const generateDateRange = (startDateString: string, endDateString: string): string[] => {
  const startDate = dayjs(startDateString)
  const endDate = dayjs(endDateString)
  const dateRange: string[] = []

  let currentDate = startDate

  while (currentDate.isBefore(endDate) || currentDate.isSame(endDate, "day")) {
    const formattedDate = currentDate.format("MMM D")

    dateRange.push(formattedDate)
    currentDate = currentDate.add(1, "day")
  }

  return dateRange
}

/**
 * 千分位格式化, 支持格式化正负整数、小数、带货币符号的数值
 * @param num
 */
const formatNumber = (num: number | string):string => {
  const [integerPart, decimalPart] = (num + "").split(".")
  const reg = /\B(?=(\d{3})+(?!\d))/g

  const formattedInteger = integerPart.replace(reg, ",")

  return decimalPart ? `${formattedInteger}.${decimalPart}` : formattedInteger
}

const formatStringFloatNumber = (numStr: string) => {
  // 将字符串转换为数字
  const numericValue = parseFloat(numStr)

  // 如果无法转换为有效数字，则返回原始输入
  if (isNaN(numericValue)) {
    return numStr
  }

  // 使用 toLocaleString 添加千分位分隔符，并保留两位小数
  return numericValue.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })
}

/**
 * 小数转百分比
 * @param num
 */
const percentNumber = (num: number | string) => {
  if (num === "-") return num
  if (typeof num === "string") {
    return num.includes("%")
      ? num
      : num + "%"
  }
  return (Math.round(num * 10000) / 100.00) + "%"
}

function checkDecimalPlaces(num:number|string, len:number) {
  // 将数字转换为字符串,并获取小数点后的位数
  const decimalPlaces = num.toString().split(".")[1]?.length || 0

  // 判断小数位数是否超过2位
  return decimalPlaces > len
}

function toFixedNumber(numStr: number | string) {
  if (numStr === "") return ""
  // 将字符串转换为数字
  const num = typeof numStr === "number" ? numStr : parseFloat(numStr)

  // 如果是整数或者小数部分不超过2位,直接返回原字符串
  if (Number.isInteger(num) || !checkDecimalPlaces(num, 2)) {
    return numStr
  }
  // 如果是小数,保留最多2位小数
  else {
    return num.toFixed(2)
  }
}


export {
  currencyFormat,
  formatNumber,
  formatStringFloatNumber,
  generateDateRange,
  percentNumber,
  toFixedNumber,
}
