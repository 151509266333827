import Permission from "@/enums/permission"

export interface PermissionRules {
  /** 用户的套餐等级 */
  plan_member?: number

  /** 用户的套餐价格 */
  plan_price?: number

  /** 管理员授权的权限 */
  paid_service_func_config?: string

  /** true: 允许付费的老用户 */
  oldPayingUsers?: boolean

  /** true: 允许老用户 */
  isOldUser?: boolean

  /** 高亮的功能点 引导弹窗中使用 */
  active_index: number[]
}


const permissionRules: Record<Permission, PermissionRules> = {
  [Permission.AutoTranslateTrackingDetails]: {
    // 管理员赋予了 auto_translate 权限
    paid_service_func_config: "auto_translate",

    // 权限划分 3 Automatically translate tracking details
    plan_member: 3,

    // 老用户付费金额>=$49
    plan_price: 49,

    active_index: [0],
  },

  [Permission.CustomOrderStatus]: {
    // 老用户
    isOldUser: true,

    // 权限划分 2 Custom order status
    plan_member: 2,

    active_index: [1],
  },

  [Permission.EstimatedDeliveryTime]: {
    // 老用户
    isOldUser: true,

    // 权限划分 2 Estimated delivery time
    plan_member: 2,
    active_index: [0],
  },

  [Permission.ProductRecommendations]: {


    // 老用户
    isOldUser: true,

    // 权限划分 2 Product recommendation & upsell
    plan_member: 2,
    active_index: [2],

  },

  [Permission.ProductDiscount]: {

    // 老用户
    isOldUser: true,

    // 权限划分 2 Product recommendation & upsell
    plan_member: 2,
    active_index: [3],
  },

  [Permission.CSVExport]: {

    // 付费老用户
    oldPayingUsers: true,


    // 管理员赋予了 export 权限
    paid_service_func_config: "export",

    // 权限划分 3 CSV export
    plan_member: 3,
    active_index: [1],
  },

  [Permission.ParcelPanelShippingNotifications]: {
    // 付费老用户
    oldPayingUsers: true,

    // 权限划分 2 ParcelPanel shipping notifications
    plan_member: 2,
    active_index: [4],
  },

  [Permission.IntegrateAppPayPal]: {
    // 权限划分 3 PayPal
    plan_member: 3,
    active_index: [3],
  },

  [Permission.PreEDD]: {
    plan_member: 3,
    active_index: [2],
  },

  [Permission.IntegrateAppYotpo]: {
    // 权限划分 3 PayPal
    plan_member: 3,
    active_index: [3],
  },

  [Permission.IntegrateAppKlaviyo]: {
    // 老用户
    isOldUser: true,

    // 权限划分 3 Klaviyo API integration
    plan_member: 3,
    active_index: [3],
  },

  [Permission.IntegrateAppOmnisend]: {
    // 老用户
    isOldUser: true,

    // 权限划分 3 Klaviyo API integration
    plan_member: 3,
    active_index: [2],

  },

  // Remove ParcelPanel branding
  [Permission.RemoveBranding]: {
    // 老用户
    isOldUser: true,
    plan_member: 3,
    active_index: [5],

  },

  [Permission.WebhookIntegration]: {
    // 老用户
    isOldUser: true,

    // 权限划分 3 Developer API & Webhook
    plan_member: 3,
    active_index: [3],
  },

  [Permission.APIIntegration]: {
    // 老用户
    isOldUser: true,

    // 权限划分 3 Developer API & Webhook
    plan_member: 3,
    active_index: [4],

  },

  [Permission.IntegrateAppGorgias]: {
    // 老用户
    isOldUser: true,

    // 权限划分 3 Klaviyo API integration
    plan_member: 3,
    active_index: [3],
  },
  [Permission.AdvancedAnalytics]: {
    oldPayingUsers: true,

    // 权限划分 2 Custom order status
    plan_member: 2,

    active_index: [5],
  },

  [Permission.ProductRecommendationAndDiscountUpsell]: {
    oldPayingUsers: true,

    // 权限划分 2 Custom order status
    plan_member: 2,

    active_index: [2, 3],
  },
}

export default permissionRules
