import { useMount } from "@channelwill/hooks"
import {
  Banner,
  Button,
  Grid,
  Layout,
  LegacyCard,
  Link,
  Popover,
  ProgressBar,
  Text,
  Tooltip,
} from "@shopify/polaris"
import { EditIcon, GiftCardFilledIcon, GiftCardIcon, HeartIcon } from "@shopify/polaris-icons"
import classNames from "classnames"
import dayjs from "dayjs"
import { Observer } from "mobx-react"
import React, { useEffect, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { useIntercom } from "react-use-intercom"

import { AppPricingInterval } from "@/api/account/type.d"
import { APICode } from "@/api/APICode"
import appApi from "@/api/app"
import dashboard from "@/api/dashboard"
import { BannerInterface, BannerResponse } from "@/api/dashboard/type"
import {
  Container,
  KlaviyoMigrationReminderBanner,
  SharingBanner,
  ShipmentsLookupsChart,
} from "@/component"
import CustomPage from "@/component/CustomPage/CustomPage"
import DatePicker from "@/component/DatePicker"
import { ImageBanner } from "@/component/ImageBanner/ImageBanner"
import { MarketingPicture } from "@/component/ImageBanner/MarketingPicture"
import PolarisIndicator from "@/component/PolarisIndicator"
import Permission from "@/enums/permission"
import PartnerFriendlyBanner from "@/pages/Account/components/Banners/PartnerFriendlyBanner"
import AnalyticCard from "@/pages/Analytics/components/AnalyticCard"
import AnalyticInfos from "@/pages/Analytics/components/AnalyticInfos"
import AnalyticsPaidUpgrade from "@/pages/Analytics/components/PaidUpgrade/PaidUpgrade"
import { ChineseWechatMarketingBanner } from "@/pages/Dashboard/components"
import BannerCarousel from "@/pages/Dashboard/components/BannerCarousel"
import OrderStatusList from "@/pages/Dashboard/components/OrderStatusList"
import QuickSetupGuide from "@/pages/Dashboard/components/QuickSetupGuide"
import SetupGuideSkeleton from "@/pages/Dashboard/components/QuickSetupGuide/SetupGuideSkeleton"
import SpecialOffer from "@/pages/Dashboard/components/SpecialOffer"
import StaffingCost from "@/pages/Dashboard/components/ValueCalculation/StaffingCost"
import StaffingTime from "@/pages/Dashboard/components/ValueCalculation/StaffingTime"
import { TableDatePickerOnChangeHandle } from "@/pages/Dashboard/Events"
import HelpCenterCard from "@/pages/GetHelp/components/HelpCenterCard"
import stores from "@/stores"
import { currencyFormat, formatNumber, percentNumber, toFixedNumber } from "@/utils/analyticsUtils"
import toast from "@/utils/toast"

import styles from "./Dashboard.module.scss"
import DashboardInit from "./init"

export default function Dashboard() {
  const { t } = useTranslation("dashboard")
  const { t: commonT } = useTranslation("common")
  const navigator = useNavigate()

  // 关于推荐banner弹窗
  const [bannerLoading, setBannerLoading] = useState(true)
  const [staffingTimeModal, setStaffingTimeModal] = useState(false)
  const [staffingCostModal, setStaffingCostModal] = useState(false)
  const [recommendedBanners, setRecommendedBanners] = useState<BannerInterface[]>([])
  const [quickSetupSwitching, setQuickSetupSwitching] = useState(false)

  // 用户信息
  const { userInfoSore, EventsStore } = stores
  const { isFreeUser, data: { money_with_currency_format } } = userInfoSore

  // 第 1 个图表的相关数据
  const { shipmentsLookupsDataStore } = stores

  useMount(() => {
    DashboardInit.init()
    dashboard.getBanner()
      .then(({ data }: { data: BannerResponse }) => {
        setRecommendedBanners(data.banners)
      })

    EventsStore.setStartTime(void 0)
    EventsStore.setEndTime(void 0)
    EventsStore.setSelected("60")
  })

  const handleQuickSetupChange = () => {
    setQuickSetupSwitching(true)
    setTimeout(() => setQuickSetupSwitching(false), 300)
  }

  return (
    <Observer>{() => {
      // 展示付费引导
      const showPaidUpgrade = isFreeUser || (userInfoSore?.isOldUser && !userInfoSore?.oldPayingUsers)
      // 节省人力成本价值
      const staffingCost = window.Currency ? toFixedNumber(window.Currency.convert(
        shipmentsLookupsDataStore.getStaffingCost,
        shipmentsLookupsDataStore.as_value_coefficient.cost_monetary_unit,
        shipmentsLookupsDataStore.getCurrency(),
      )) : 0

      // 后端给的conversion revenue总数，实际需要类加上节省人力成本价值
      let conversionRevenueTotal = Number(shipmentsLookupsDataStore.conversion_revenue.total)

      // total和staffingCost相加作为总的值，如果staffingCost货币转换不了（Shopify缺少汇率情况下），则不加该值
      if (shipmentsLookupsDataStore.as_value_coefficient.cost_monetary_unit === userInfoSore.data.currency) {
        conversionRevenueTotal = Number(toFixedNumber(conversionRevenueTotal  + Number(staffingCost)))
      }

      // 如果免费用户，则没有total的值只有人力价值的值
      if (showPaidUpgrade){
        conversionRevenueTotal = Number(toFixedNumber(Number(staffingCost)))
      }

      return <Container id="Page-Dashboard">
        <Layout>
          <Layout.Section>
            <CustomPage
              title={<Trans ns="dashboard" i18nKey="welcome_h1">
                <Link removeUnderline onClick={() => navigator("/integration?selected_tab=9")} />
              </Trans>}
              paddingBlockEnd={{ xs: "0", sm: "0", md: "200", lg: "200", xl: "200" }}
              rightChildren={<div className={"flex justify-between items-center flex-wrap gap-2"}>
                <div
                  className={classNames("mt-2 sm:mt-0 mb-2 sm:mb-0 flex flex-col", styles.datePicker)}
                >
                  <Tooltip
                    content={
                      // @ts-ignore
                      t("QuotaAvailable", { count: formatNumber(userInfoSore.data.remaining_order_count) })
                    }
                    preferredPosition={"below"}
                    width={"wide"}
                    hasUnderline
                  >
                    <Text variant="bodySm" fontWeight="semibold" as="span">
                      {t("QuotaRemainingTotal")} {formatNumber(userInfoSore.data.remaining_order_count)}
                      {" / "}
                      {formatNumber(userInfoSore.data.total_order_count || 0)}
                    </Text>
                  </Tooltip>

                  <div className={"mt-[2px]"}>
                    <ProgressBar
                      progress={
                        userInfoSore.data.remaining_order_count / userInfoSore.data.total_order_count * 100
                      }
                      size="small"
                    />
                  </div>
                </div>

                <DatePicker
                  preferredAlignment={"right"}
                  buttonProps={{ variant: "secondary" }}
                  onApply={(selected: number, startDate: Date, endDate: Date) => {
                    TableDatePickerOnChangeHandle(selected, startDate, endDate)
                    
                    EventsStore.setStartTime(dayjs(startDate).format("YYYY-MM-DD"))
                    EventsStore.setEndTime(dayjs(endDate).format("YYYY-MM-DD"))
                    EventsStore.setSelected(String(selected))
                  }}
                />
                <SpecialOffer />
              </div>}
            />

          </Layout.Section>

          {
            userInfoSore.data.is_legacy_klaviyo_connected
            && (
              <Layout.Section>
                <KlaviyoMigrationReminderBanner />
              </Layout.Section>
            )
          }

          <Layout.Section>
            <MarketingPicture
              show={userInfoSore.data.banner_discount_v2024_04.dashboard}
              src={userInfoSore.data.banner_discount_v2024_04.main_banner_url}
              onClose={() => {
                appApi.dispatchMarketingBanner("dashboard")
                  .then(({ data }) => {
                    userInfoSore.setDataSingle("banner_discount_v2024_04", {
                      ...userInfoSore.data.banner_discount_v2024_04,
                      ...data.banner_discount_v2024_04,
                    })
                  })
              }}
              to={"/account"}
            />
          </Layout.Section>

          {/* 额度不足提醒*/}
          {userInfoSore.local.showQuotaRemain
            && userInfoSore.showQuotaNotice
            && <Layout.Section>
              <QuotaNoticeBanner
                // 套餐版本为3为新版本
                isNewPlanVersion={userInfoSore.data.current_subscription?.version >= 3}
                consumeOrderCount={userInfoSore.data.remaining_order_count}
                onDismiss={() => userInfoSore.setLocalVar("showQuotaRemain", false)}
                planPrice={userInfoSore.data.plan}
                isAnnually={userInfoSore.data.plan_interval === AppPricingInterval.Annual}
              />
            </Layout.Section>}

          <Layout.Section>
            {userInfoSore.data.discount.banner_id && userInfoSore.data.discount_start && <ImageBanner
              id={userInfoSore.data.discount.banner_id}
              show={!userInfoSore.data.discount.banner_displayed}
              onClose={() => userInfoSore.setDiscountSingle("banner_displayed", true)}
              to="/account"
              src={userInfoSore.data.discount.banner_img}
              style={{ paddingBottom: 0 }}
            />}
          </Layout.Section>

          <Layout.Section>
            {/* 合作伙伴友好提示框*/}
            {stores.userInfoSore.showPartnerFriendly && <PartnerFriendlyBanner />}
          </Layout.Section>
          <Layout.Section>
            {/* 轮播banner */}
            <BannerCarousel bannerList={recommendedBanners} removePromotionalBanner={(id: number) => {
              const banners = recommendedBanners.filter(item => item.id !== id)

              setRecommendedBanners(banners)
            }}
            >
              {/* 联系我们添加 免费用户额度 */}
              {userInfoSore.local.showFreeAdd
              && userInfoSore.showFreeOrderAdd &&
              <RemoveAddOrderForFreeBanner onDismiss={() => stores.userInfoSore.setLocalVar("showFreeAdd", false)} />}
            </BannerCarousel>
          </Layout.Section>

          <Layout.Section>
            {
              /* 用户套餐异常提示 */
              userInfoSore.data.show_account_tip
              && <ShowAccountPlanErr onDismiss={() => userInfoSore.setDataKey("show_account_tip", false)} />
            }

            {!!userInfoSore.data.is_in_cn && <ChineseWechatMarketingBanner />}

            {/* 联盟推广分享 */}
            {userInfoSore.showSharingBanner && <SharingBanner />}
          </Layout.Section>

          <Layout.Section>

            {
              stores.userInfoSore.quickSetupInitialized
                ? <QuickSetupGuide onChangeStep={handleQuickSetupChange} />
                : <SetupGuideSkeleton />
            }

            <div className={"mb-4"}>
              <Grid gap={{ xs: "1rem", sm: "1rem", md: "1rem", lg: "1rem", xl: "1rem" }}>
                <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }}>
                  <AnalyticCard
                    disabledTooltip={quickSetupSwitching}
                    title={t("ShipmentsLookups.title")}
                    description={t("ShipmentsLookupsDesc")}
                    amount={`${formatNumber(shipmentsLookupsDataStore.shipments_count)} / ${formatNumber(shipmentsLookupsDataStore.lookups_count)}`}
                  >
                    <AnalyticInfos
                      disabledTooltip={quickSetupSwitching}
                      className={"mt-3"}
                      infos={[
                        {
                          title: t("ShipmentsLookups.deliveryPerformance"),
                          description: t("ShipmentsLookups.deliveryPerformanceDesc"),
                          amount: shipmentsLookupsDataStore.delivery_performance.value,
                          render(value) {
                            if (value === 0) return "-"
                            return <>
                              {formatNumber(value)}{" "}
                              <Text as={"span"} tone={"subdued"}>({percentNumber(shipmentsLookupsDataStore.delivery_performance.percentage || "-")})</Text>
                            </>
                          },
                        },
                        {
                          title: t("ShipmentsLookups.validTracking"),
                          description: t("ShipmentsLookups.validTrackingDesc"),
                          amount: shipmentsLookupsDataStore.valid_tracking.value,
                          render(value) {
                            if (value === 0) return "-"
                            return <>
                              {formatNumber(value)}{" "}
                              {
                                !shipmentsLookupsDataStore.valid_tracking.percentage ? ""
                                  : <Text as={"span"} tone={"subdued"}>({!value ? "-" : percentNumber(shipmentsLookupsDataStore.valid_tracking.percentage || "-")})</Text>
                              }
                            </>
                          },
                        },
                        {
                          title: t("ShipmentsLookups.savedStaffingTime"),
                          description: t("ShipmentsLookups.savedStaffingTimeDesc"),
                          icon: EditIcon,
                          iconClick: () => setStaffingTimeModal(true),
                          amount: shipmentsLookupsDataStore.getStaffingTime
                            ? t("workingHours", { hours: shipmentsLookupsDataStore.getStaffingTime })
                            : "-",
                        },
                      ]}
                    />
                  </AnalyticCard>
                </Grid.Cell>
                <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }}>
                  <div className={styles.cardFull}>
                    <AnalyticCard
                      title={t("TotalConversionRevenue")}
                      description={t("TotalConversionRevenueDesc")}
                      amount={currencyFormat(String(conversionRevenueTotal), showPaidUpgrade ? window.Currency && window.Currency.rates[userInfoSore.data.currency] ? money_with_currency_format : "${{amount}} USD" : money_with_currency_format, "-")}
                    >
                      {
                        showPaidUpgrade ? <div className={"mt-3"}>
                          <AnalyticsPaidUpgrade
                            lines={3}
                            primaryButton
                            hiddenButton
                            permission={Permission.ProductRecommendationAndDiscountUpsell}
                            subButton={<Trans ns="dashboard" i18nKey="upgradeTips">
                              <Button
                                onClick={() => window.open("https://docs.parcelpanel.com/shopify/getting-started/increasing-sales-revenue/")}
                                variant="plain"
                              >
                              Tip
                              </Button>
                            </Trans>}
                          />

                          <AnalyticInfos
                            className={"mt-5"} // 为了使带付费引导的对齐，这里的mt为20px
                            infos={[
                            // 如果改这里,下面的非付费引导,也需要同步改动,若抽离可读性下降
                              {
                                title: t("SavedStaffingCost"),
                                description: t("SavedStaffingCostDesc"),
                                icon: EditIcon,
                                iconClick: () => setStaffingCostModal(true),
                                amount: window.Currency ? toFixedNumber(window.Currency.convert(
                                  shipmentsLookupsDataStore.getStaffingCost,
                                  shipmentsLookupsDataStore.as_value_coefficient.cost_monetary_unit,
                                  shipmentsLookupsDataStore.getCurrency(),
                                )) : "-",
                                render(value) {
                                  return <>{
                                    currencyFormat(value as string,
                                      window.Currency && window.Currency.rates[userInfoSore.data.currency] ? money_with_currency_format : "${{amount}} USD", "-")
                                  }</>
                                },
                              },
                            ]}
                          />
                        </div>
                          : <AnalyticInfos
                            className={"mt-3"}
                            infos={[
                              {
                                title: t("TrackingPage"),
                                description: t("TrackingPageDesc"),
                                amount: shipmentsLookupsDataStore.conversion_revenue.tracking_page,
                                render(value) {
                                  return <>{currencyFormat(value as string, money_with_currency_format, "-")}</>
                                },
                              },
                              {
                                title: t("ShippingNotifications"),
                                description: t("ShippingNotificationsDesc"),
                                amount: shipmentsLookupsDataStore.conversion_revenue.shipping_notifications,
                                render(value) {
                                  return <>{currencyFormat(value as string, money_with_currency_format, "-")}</>
                                },
                              },

                              // 如果改这里,上面的付费引导,也需要同步改动,若抽离可读性下降
                              {
                                title: t("SavedStaffingCost"),
                                description: t("SavedStaffingCostDesc"),
                                icon: EditIcon,
                                iconClick: () => setStaffingCostModal(true),
                                amount: staffingCost || "-",
                                render(value) {
                                  if (value === "-") return value
                                  return <>{
                                    currencyFormat(value as string,
                                      window.Currency && window.Currency.rates[userInfoSore.data.currency] ? money_with_currency_format : "${{amount}} USD", "-")
                                  }</>
                                },
                              },
                            ]}
                          />
                      }
                    </AnalyticCard>
                  </div>
                </Grid.Cell>
              </Grid>
            </div>

            <StaffingTime
              open={staffingTimeModal}
              loading={shipmentsLookupsDataStore.save_loading}
              initValue={shipmentsLookupsDataStore.as_value_coefficient.save_time}
              onClose={() => setStaffingTimeModal(false)}
              onSave={(value) => {
                shipmentsLookupsDataStore.saveValueCoefficient({ save_time: value }).then((res) => {
                  toast(commonT("SavedSuccess"))
                  setStaffingTimeModal(false)
                })
              }}
            />

            <StaffingCost
              open={staffingCostModal}
              loading={shipmentsLookupsDataStore.save_loading}
              prefix={shipmentsLookupsDataStore.getCurrency()}
              inputLoading={window.Currency && !!window.Currency.rates[userInfoSore.data.currency]
                && userInfoSore.data.currency !== shipmentsLookupsDataStore.as_value_coefficient.cost_monetary_unit
              }
              initValue={shipmentsLookupsDataStore.as_value_coefficient.save_cost}
              onClose={() => setStaffingCostModal(false)}
              onSave={(value) => {
                shipmentsLookupsDataStore.saveValueCoefficient({ save_cost: value }).then(() => {
                  toast(commonT("SavedSuccess"))
                  setStaffingCostModal(false)
                })
              }}
            />

            <div className={"mb-4"}>
              <OrderStatusList
                dataset={shipmentsLookupsDataStore.shipmentStatus}
                chartData={shipmentsLookupsDataStore.shipment_status_chart_data}
                orderTime={{
                  selected: EventsStore.selected,
                  orderedAtMin: EventsStore.startTime,
                  orderedAtMax: EventsStore.endTime,
                }}
              />
            </div>

            {/* 图表*/}
            <LegacyCard title={t("ShipmentsLookups.title")}>
              <ShipmentsLookupsChart />
            </LegacyCard>

          </Layout.Section>

          <Layout.Section>
            <HelpCenterCard />
          </Layout.Section>
        </Layout>
      </Container>
    }}</Observer>
  )
}

/**
 * 额度不足提醒
 * @param consumeOrderCount
 * @param onDismiss 回调
 * @param isNewPlanVersion 是新版本套餐（3）
 * @param planPrice  套餐价格
 * @param isAnnually 是否为年费套餐
 */
export function QuotaNoticeBanner({
  consumeOrderCount,
  onDismiss,
  isNewPlanVersion,
  planPrice,
  isAnnually,
}: {
  consumeOrderCount: number,
  planPrice: number,
  onDismiss: CallableFunction
  isNewPlanVersion: boolean
  isAnnually: boolean
}) {
  const navigate = useNavigate()
  const { t } = useTranslation("dashboard", { keyPrefix: "quotaNotice" })
  const { t: commonT } = useTranslation("common")

  const count = Number(consumeOrderCount)
    .toLocaleString()

  return (
    <Banner
      title={t("title")}
      action={{
        content: t("upgradeNow"), onAction: () => {
          stores.commonStore.setAccountSource("/dashboard")
          navigate("/account")
        },
      }}
      secondaryAction={{
        // @ts-ignore
        variant: "destructive",
        content: commonT("LearnMore"),
        onAction: () => window.open("https://docs.parcelpanel.com/shopify/plan-billing/pricing/"),
      }}
      tone="warning" onDismiss={() => onDismiss()}
    >
      {
        isNewPlanVersion && planPrice > 0
          ? <>
            <p>{t(isAnnually ? "content" : "newContent", { replace: { consumeOrderCount: count } })}</p>
            <p>{t("newContent2")}</p>
          </>
          : <>
            <p>{t("content", { replace: { consumeOrderCount: count } })}</p>
            <p>{t("content2")}</p>
          </>
      }

    </Banner>
  )
}

/**
 * 店铺套餐异常提示
 * @param onDismiss
 * @constructor
 */
function ShowAccountPlanErr({ onDismiss }: { onDismiss: CallableFunction }) {

  const navigate = useNavigate()
  const { t } = useTranslation("dashboard", { keyPrefix: "quotaAccountPlan" })

  return <>
    <Banner
      title={t("title")}
      action={{
        content: t("upgradeNow"), onAction: () => {
          navigate("/account")
          stores.commonStore.setAccountSource("/dashboard")
        },
      }}
      tone="critical"
      onDismiss={() => {
        dashboard.closeShowAccountTip("show_account_tip")
          .then(res => {
            const { data } = res

            if (data.code === APICode.OK) onDismiss()
          })
      }}
    >
      <p>{t("content")}</p>
    </Banner>
  </>
}

/**
 * 渲染针对免费用户添加额度提示框
 *
 * @param onDismiss 回调函数
 */
function RemoveAddOrderForFreeBanner({ onDismiss }: { onDismiss: CallableFunction }) {
  const { showNewMessages } = useIntercom()
  const { t } = useTranslation("dashboard", { keyPrefix: "freeQuotaBanner" })

  return <>
    <Banner
      title={t("title")}
      tone="info"
      icon={GiftCardFilledIcon}
      action={{
        content: t("action"), onAction() {
          showNewMessages(t("concatUsMsg"))
        },
      }}
      onDismiss={() => {
        dashboard.closeFreeOrderAddBanner()
          .then(res => {
            const { data } = res

            if (data.code === APICode.OK) onDismiss()
          })
      }}
    >
      <p>{t("content")}</p>
    </Banner>
  </>
}
