import { DatePicker, LegacyStack, TextField } from "@shopify/polaris"
import dayjs from "dayjs"
import React, { useCallback, useEffect, useState } from "react"

import { checkDatetimeFormat } from "@/utils/datetime"


interface IProps {
  label?: React.ReactNode
  selected: dayjs.Dayjs
  disableDatesBefore?: Date  // todo 如果想用 dayjs 的话就再改吧
  disableDatesAfter?: Date  // todo 如果想用 dayjs 的话就再改吧
  textFormat: string

  onChange(date: dayjs.Dayjs): void
}

const TextErrorMsg = {

  format: "Must match {format} format",
  // todo 命名规范 及 动态参数 days
  over: "Only supports dates within 120 days of history",
}

export function TextDatePicker({
  textFormat = "YYYY-MM-DD",
  label,
  selected,
  disableDatesBefore,
  disableDatesAfter,
  onChange,
}: IProps) {

  const [selectedDate, setSelectedDate] = useState<Date>()
  const [{ month, year }, setDate] = useState({ month: 1, year: 2022 })

  const handleMonthChange = useCallback(
    (month, year) => setDate({ month, year }),
    [],
  )

  const handleStartingDateChange = useCallback(
    ({ start }) => onChange(dayjs(start)),
    [],
  )

  const [textValue, setTextValue] = useState("")
  const [textError, setTextError] = useState<string | boolean>(false)


  useEffect(() => {
    setDate({ month: selected.month(), year: selected.year() })
    setTextValue(selected.format(textFormat)
      .toString())
    setSelectedDate(selected.toDate())
  }, [selected])

  const handleTextChange = useCallback(
    (value) => {
      setTextValue(value)

      if ("" === value) {
        setTextError(TextErrorMsg.format.replace("{format}", textFormat))
        return
      }

      const dateDayjs: dayjs.Dayjs | null = checkDatetimeFormat(textFormat, value)

      if (null === dateDayjs) {
        setTextError(TextErrorMsg.format.replace("{format}", textFormat))
        return
      }

      // 如果输入的日期大于过去90天的日期
      if (disableDatesBefore && dateDayjs.isBefore(disableDatesBefore)) {
        // console.log(dayjs(disableDatesBefore).diff(dayjs()))
        setTextError(TextErrorMsg.over)
        return
      }

      // 如果输入的日期大于今天的
      if (disableDatesAfter && dateDayjs.isAfter(disableDatesAfter)) {
        setTextError(TextErrorMsg.over)
        return
      }

      setTextError(false)

      onChange(dateDayjs)
    },
    [disableDatesBefore, disableDatesAfter],
  )

  return (
    <LegacyStack vertical>
      <TextField
        label={label}
        autoComplete="off"
        placeholder="YYYY-MM-DD"
        value={textValue}
        onChange={handleTextChange}
        error={textError}
      />
      <DatePicker
        month={month}
        year={year}
        disableDatesBefore={disableDatesBefore}
        disableDatesAfter={disableDatesAfter}
        onMonthChange={handleMonthChange}
        selected={selectedDate}
        onChange={handleStartingDateChange}
      />
    </LegacyStack>
  )
}
