import { Badge, Page } from "@shopify/polaris"
import { Observer } from "mobx-react"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Outlet, useLocation, useNavigate } from "react-router-dom"

import { Container } from "@/component"
import Tabs from "@/component/Tabs/index"
import stores from "@/stores"

const Settings = () => {
  const { commonStore } = stores
  const { t } = useTranslation("settings")
  const [tabIndex, setTabIndex] = useState(0)
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const tabList = [
    // { content: "Old setting", id: "/settings/old" },
    { content: t("General"), id: "/settings/general" },
    { content: t("ShippingNotifications"), id: "/settings/shipping-notifications" },
    {
      content: <div className={"gap-2 flex items-center"}>
        {t("PrePurchaseEDD")}
        <Badge tone={"info"}>{t("New")}</Badge>
      </div>,
      id: "/settings/pre_purchase_edd",
    },
  ]

  useEffect(() => {
    const targetPageIndex = tabList.findIndex(tab => tab.id === pathname)

    if (targetPageIndex === -1){
      setTabIndex(0)
    } else if (targetPageIndex !== tabIndex){
      setTabIndex(targetPageIndex)
    }
  }, [pathname])

  return <Observer>{() => <Container maxWidth={"950px"} className={"mt-0"} fullWidth={false}>
    <Page title={t("Settings")}>
      <Tabs
        tabs={tabList}
        activeIndex={tabIndex}
        onChange={(index:number) => {
          if (tabIndex !== index){
            navigate(tabList[index].id || "/settings")
            if (!commonStore.contextualSaveBar.show) {
              setTabIndex(index)
            }
          }
        }}
      />
      <div className={"mt-4"}>
        <Outlet />
      </div>
    </Page>
  </Container>
  }</Observer>
}

export default Settings
