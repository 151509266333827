import { ActionList, Icon, LegacyCard, Popover, SkeletonBodyText, SkeletonDisplayText } from "@shopify/polaris"
import { ChevronUpIcon, MenuHorizontalIcon, XIcon } from "@shopify/polaris-icons"
import { useTranslation } from "react-i18next"

import styles from "./index.module.scss"

const SetupGuideSkeleton = () => {
  const { t } = useTranslation("dashboard")

  return (
    <div className={"mb-4"}>
      <LegacyCard
        title={<SkeletonDisplayText size="medium" />}
        actions={[
          // @ts-ignore
          {
            content:
              <Popover
                active={false}
                activator={<div className={styles.actionButton}>
                  {/* @ts-ignore*/}
                  <Icon source={MenuHorizontalIcon} />
                </div>}
                onClose={() => null}
              >
                <ActionList
                  actionRole="menuitem"
                  /* @ts-ignore*/
                  items={[{ content: t("dismiss"), icon: XIcon, onAction: () => null }]}
                />
              </Popover>,
          },
          {
            // @ts-ignore
            content: <div className={styles.actionButton}>
              {/* @ts-ignore*/}
              <Icon source={ChevronUpIcon} />
            </div>,
          },
        ]}
      >
        <LegacyCard.Section flush>
          <div className={"p-5"}>
            <SkeletonBodyText lines={1} />
          </div>
        </LegacyCard.Section>
        {
          <LegacyCard.Section>
            <SkeletonDisplayText size="medium" />
            <div className={"mt-4"}></div>
            <SkeletonBodyText lines={4} />
            <div className={"mt-4"}></div>
            <SkeletonDisplayText size="medium" />
            <div className={"mt-4"}></div>
            <SkeletonBodyText lines={4} />
            <div className={"mt-4"}></div>
            <SkeletonDisplayText size="medium" />
          </LegacyCard.Section>
        }
      </LegacyCard>
    </div>
  )
}

export default SetupGuideSkeleton
